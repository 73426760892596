import React from 'react';
import { Intercom } from '../../lib/intercom';
import { RoutePaths } from '../../routes';
import { ContentWrapper } from '../content_wrapper/content_wrapper';
import { ScrollToTop } from '../scroll_to_top/scroll_to_top';
import { SEO } from '../seo/seo';
import { SpanLink } from '../span_link/span_link';
import styles from './error_page.less';
import { OmniaButton } from '../omnia_button/omnia_button';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';

interface ErrorProps {
  context: 'error' | 'not_found';
}

const ErrorContent = [
  {
    context: 'error',
    header: 'An unexpected error occurred',
    subheader: 'Please try again. If the issue continues,',
    contact: 'contact support.',
  },
  {
    context: 'not_found',
    header: 'Page Not Found',
    subheader: `The link you requested is incorrect or may be out-of-date. If the issue continues,`,
    contact: 'contact support.',
  },
];

const ErrorPage = (props: ErrorProps) => {
  const { context } = props;
  const message = ErrorContent.find((i) => i.context === context);

  return (
    <ScrollToTop>
      {context === 'not_found' ? <SEO title="Page Not Found" /> : ''}
      <section className={styles.errorBackground}>
        <ContentWrapper className={styles.errorPageContainer}>
          <div className={styles.errorText}>
            <h1 className={styles.header}>{message.header}</h1>
            <h2 className={styles.subheader}>
              {message.subheader}{' '}
              <SpanLink
                className={styles.contactLink}
                onClick={() => {
                  Intercom.open();
                }}
              >
                {message.contact}
              </SpanLink>
            </h2>
          </div>
          <div className={styles.errorButtons}>
            {context === 'error' && (
              <OmniaButton
                size="lg"
                kind="primary"
                onClick={() => {
                  window.location.reload();
                }}
              >
                {' '}
                Try Again{' '}
              </OmniaButton>
            )}
            <OmniaLinkButton
              size="lg"
              kind={context === 'not_found' ? 'primary' : 'tertiary'}
              to={`${RoutePaths.HOME}`}
            >
              {' '}
              Return Home{' '}
            </OmniaLinkButton>
          </div>
        </ContentWrapper>
      </section>
    </ScrollToTop>
  );
};

export default ErrorPage;
