import React from 'react';
import SvgWaypointGreatSpotIcon from '../svg/waypoint_great_spot_icon';
import { Waypoint } from '@omniafishing/core';
import SvgWaypointMarkerIcon from '../svg/waypoint_marker_icon';
import SvgWaypointFishIcon from '../svg/waypoint_fish_icon';
import SvgWaypointHazardIcon from '../svg/waypoint_hazard_icon';
import SvgWaypointStructureIcon from '../svg/waypoint_structure_icon';

export const WaypointMarker = ({ waypoint_type }: { waypoint_type: Waypoint['waypoint_type'] }) => {
  switch (waypoint_type) {
    case 'marker':
      return <SvgWaypointMarkerIcon />;
    case 'great_spot':
      return <SvgWaypointGreatSpotIcon />;
    case 'fish':
      return <SvgWaypointFishIcon />;
    case 'hazard':
      return <SvgWaypointHazardIcon />;
    case 'structure':
      return <SvgWaypointStructureIcon />;
    default:
      return null;
  }
};
