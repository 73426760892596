import { getServerEnv, ServerEnvs } from '../env';

const env = getServerEnv();

export const INTERNALS = {
  getIntercom: () => {
    const intercom = (window as any).Intercom;
    const fakeIntercom = (command: string) =>
      env !== ServerEnvs.PRODUCTION && console.log('Intercom: ' + command);
    return intercom || fakeIntercom;
  },
};

class IntercomClass {
  open() {
    const intercom = INTERNALS.getIntercom();
    intercom('show');
  }
  hide() {
    // this doesn't reliably work. use the Intercom admin to hide on specific pages
    const intercom = INTERNALS.getIntercom();
    intercom('update', {
      hide_default_launcher: true,
    });
  }
  unHide() {
    const intercom = INTERNALS.getIntercom();
    intercom('update', {
      hide_default_launcher: false,
    });
  }
}

export const Intercom = new IntercomClass();
