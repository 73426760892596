import { CategoryNames, FishingReport, Product } from '@omniafishing/core';
import _ from 'lodash';

export const baitCategoryNames: CategoryNames[] = [
  'jigs',
  'softbaits',
  'hardbaits',
  'rigs',
  'spinnerbaits',
];
export const lineTerminalCategoryNames: CategoryNames[] = ['lines', 'terminal'];
export const rodsReelsCategoryNames: CategoryNames[] = ['rods', 'reels'];

export const productIsBait = (product: Product) => {
  if (!product) {
    return false;
  }
  return baitCategoryNames.includes(product.category_name);
};

export const getTopProducts = (fishingReports: FishingReport[], ipState: string) => {
  const allProducts = _.flatten(
    fishingReports.map((report) => {
      const listProducts = report.list?.products || [];
      return [report.featured_product, ...listProducts].filter(Boolean);
    })
  );

  const baitProducts = allProducts.filter((p) => productIsBait(p));

  const baitProductsTally = baitProducts.reduce((acc, p) => {
    acc[p.sku] ||= 0;
    acc[p.sku]++;
    return acc;
  }, {} as Record<string, number>);
  const baitProductsOrdered = _.orderBy(
    baitProducts,
    [(p) => baitProductsTally[p.sku], (p) => p.state_fishing_report_counters[ipState]],
    ['desc', 'desc']
  );
  const baitProductsUniqOrdered = _.uniqBy(baitProductsOrdered, 'sku');
  const baitProductsGroup = _.groupBy(baitProductsUniqOrdered, 'shopify_product_id');
  const allBaitProducts = _.flatten(Object.values(baitProductsGroup));

  return { baitProductsGroup, allBaitProducts };
};
