import _ from 'lodash';
import { AmbassadorColorDot } from '../ambassador_color_dot/ambassador_color_dot';
import { Button, Divider, Popover } from 'antd';
import { FishingReport, Waterbody } from '@omniafishing/core';
import { getImgixPath } from '../../lib/imgix';
import { getIpLatitude, getIpLongitude, getIpState } from '../../redux/geographic_location';
import { getSeasonGroupBySeasonName } from '../../redux/reference_data';
import { getUserWaterbodies } from '../../redux/user';
import { GlobalQueryParams } from '../../lib/query_string';
import { isPending, LoadingState } from '../../constants/loading_state';
import { isUserPublic, NAME_FALLBACK } from '../../lib/user';
import { Link } from 'react-router-dom';
import { OmniaUrls } from '../../lib/urls';
import { ProductDetailFishingReportsQueryParams } from '../product_detail_fishing_reports/product_detail_fishing_reports';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { sortFishingReportsByDistance } from '../../lib/fishing_reports';
import { useQueryString } from '../../hooks/use_query_string';
import { UserProfileImg } from '../user_profile_img/user_profile_img';
import { useSelector } from 'react-redux';
import { WaterbodyImg } from '../waterbody_img/waterbody_img';
import classNames from 'classnames';
import Loading from '../loading/loading';
import React, { useMemo } from 'react';
import styles from './report_recommendation_badge.less';
import SvgStar from '../svg/star';
import pluralize from 'pluralize';
import { useResponsive } from '../../hooks/use_responsive';

interface ReportRecommendationBadgeProps {
  className?: string;
  loadingState?: LoadingState;
  nearbyFishingReports: FishingReport[];
  openAndScrollToFishingReportsPanel: () => void;
  selectedProductFishingReports: FishingReport[];
  showIcon?: boolean;
  userWaterbodiesFishingReports: FishingReport[];
}
export const ReportRecommendationBadge = React.memo((props: ReportRecommendationBadgeProps) => {
  const {
    className,
    loadingState,
    nearbyFishingReports,
    openAndScrollToFishingReportsPanel,
    selectedProductFishingReports,
    userWaterbodiesFishingReports,
    showIcon,
  } = props;
  const ipLatitude = useSelector(getIpLatitude);
  const ipLongitude = useSelector(getIpLongitude);
  const ipState = useSelector(getIpState);
  const { isDesktop, isMobile } = useResponsive();
  const seasonGroupBySeasonName = useSelector(getSeasonGroupBySeasonName);
  const userWaterbodyIds = useSelector(getUserWaterbodies).map((w) => w.waterbody_id);

  const { replaceQueryString } = useQueryString();

  const fishingReportsNearby = useMemo(() => {
    return sortFishingReportsByDistance(nearbyFishingReports, ipLatitude, ipLongitude);
  }, [nearbyFishingReports.map((fr) => fr.id).join('')]);

  const fishingReportsInIpState = selectedProductFishingReports.filter((f) =>
    f.waterbody.locales.map((l) => l.state.abbr).includes(ipState)
  );

  const waterbodiesNearby = _.uniqBy(fishingReportsNearby, 'waterbody.id').map(
    (fr) => fr.waterbody
  );

  const isFavorite = (waterbody: string) => {
    return userWaterbodyIds.includes(waterbody);
  };

  const nearbyFishingReportWaterbodyCounts = {} as Record<string, number>;

  if (fishingReportsNearby.length > 0) {
    fishingReportsNearby.forEach((fishingReport) => {
      const name = fishingReport.waterbody.primary_name;
      nearbyFishingReportWaterbodyCounts[name] = nearbyFishingReportWaterbodyCounts[name] || 0;
      nearbyFishingReportWaterbodyCounts[name] = nearbyFishingReportWaterbodyCounts[name] + 1;
    });
  }

  const nearbyWaterbodiesWithReportCounts = waterbodiesNearby.map((w) => {
    return { ...w, reportCount: nearbyFishingReportWaterbodyCounts[w.primary_name] };
  });

  const hasUserWaterbodyFishingReports = userWaterbodiesFishingReports.length > 0;
  const hasNearbyFishingReports = fishingReportsNearby.length > 0;
  const hasFishingReportsInIpState = fishingReportsInIpState.length > 0;

  let header: React.ReactNode;
  let reportsToRender = [] as FishingReport[];

  if (hasUserWaterbodyFishingReports) {
    header = <div>Most Recent Reports On Your Lakes</div>;
    reportsToRender = userWaterbodiesFishingReports;
  } else if (hasNearbyFishingReports) {
    header = <div>Most Recent Reports </div>;
    reportsToRender = fishingReportsNearby;
  } else if (hasFishingReportsInIpState) {
    header = <div>Most Recent Reports In {ipState}</div>;
    reportsToRender = fishingReportsInIpState;
  }

  const productReportsCount = selectedProductFishingReports?.length;

  const handleNearbyClick = () => {
    replaceQueryString({
      [ProductDetailFishingReportsQueryParams.fishing_reports_sort]: true,
    });
    openAndScrollToFishingReportsPanel?.();
  };

  const handleWaterbodyClick = (waterbody: Waterbody) => {
    replaceQueryString({
      [ProductDetailFishingReportsQueryParams.fishing_reports_waterbody]: waterbody.waterbody_id,
    });
    openAndScrollToFishingReportsPanel();
  };

  return (
    <Popover
      trigger={isMobile ? 'click' : 'hover'}
      overlayClassName={styles.popover}
      placement="bottom"
      zIndex={100}
      content={
        <>
          {loadingState && isPending(loadingState) ? (
            <Loading
              color={'#fff'}
              className={classNames(styles.loading, styles.loading__dark)}
              text="Loading Fishing Reports"
            />
          ) : (
            <>
              <div className={styles.contentContainer}>
                {fishingReportsNearby.length > 0 && (
                  <>
                    <div>
                      <div className={styles.listHeader}>
                        Nearby Lakes -{' '}
                        <span className={styles.viewAll} onClick={handleNearbyClick}>
                          View All
                        </span>
                      </div>
                      <ul className={styles.nearbyList}>
                        {nearbyWaterbodiesWithReportCounts.slice(0, 3).map((waterbody) => (
                          <li key={waterbody.id}>
                            <div className={styles.nearbyLake}>
                              <div className={styles.mapContainer}>
                                <Link to={OmniaUrls.waterbody(waterbody)}>
                                  <WaterbodyImg
                                    waterbody={waterbody}
                                    size={{ width: 125, height: 125, padding: 5 }}
                                  />
                                </Link>
                              </div>
                              <div className={styles.nearbyInfo}>
                                <Link to={OmniaUrls.waterbody(waterbody)}>
                                  <span className={styles.waterbodyName}>
                                    {waterbody.primary_name}
                                    {waterbody.locales.length > 0 && ', '}
                                    {waterbody.locales
                                      .map((locale) => `${locale.state.abbr}`)
                                      .join(', ')}
                                    {isFavorite(waterbody.id) && (
                                      <SvgStar className={styles.star} />
                                    )}
                                  </span>
                                </Link>
                                <span
                                  className={styles.reportCount}
                                  onClick={() => handleWaterbodyClick(waterbody)}
                                >
                                  {waterbody.reportCount} Report
                                  {waterbody.reportCount > 1 ? 's' : ''} <RightOutlined />
                                </span>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <Divider className={styles.divider} type="vertical" />
                  </>
                )}
                <div>
                  <div className={styles.listHeader}>{header}</div>
                  <ul className={styles.reportByUserList}>
                    {reportsToRender.slice(0, 3).map((report) => (
                      <li key={report.id}>
                        <div className={styles.reportByUser}>
                          <div className={styles.role}>
                            <Link
                              to={OmniaUrls.user(report.user)}
                              className={classNames({
                                [styles.link__disabled]: !isUserPublic(report.user),
                              })}
                            >
                              <UserProfileImg
                                className={styles.userImg}
                                image={report.user.image}
                                imageSize={64}
                              />
                              <AmbassadorColorDot user={report.user} className={styles.dot} />
                            </Link>
                          </div>
                          <div className={styles.reportInfoList}>
                            <div className={styles.lakeLink}>
                              <Link to={OmniaUrls.waterbody(report.waterbody)}>
                                <span className={styles.waterbodyName}>
                                  {report.waterbody.primary_name}
                                  {report.waterbody.locales.length > 0 && ', '}
                                  {report.waterbody.locales
                                    .map((locale) => `${locale.state.abbr}`)
                                    .join(', ')}
                                </span>
                                {isFavorite(report.waterbody.id) && (
                                  <SvgStar className={styles.star} />
                                )}
                              </Link>
                            </div>
                            <div className={styles.reportInfo}>
                              <span
                                className={classNames(
                                  styles.seasonIcon,
                                  styles[seasonGroupBySeasonName(report.season.name).name]
                                )}
                              ></span>
                              {report.season.display_name}{' '}
                              <img
                                src={getImgixPath(report.species.image, { w: 100 })}
                                className={styles.speciesImg}
                              />{' '}
                              {report.species.display_name} / {report.technique.display_name} /{' '}
                              {report.structure.display_name} {report.forage === null ? '' : '/'}{' '}
                              {report.forage?.display_name}
                            </div>
                            <div className={styles.userInfo}>
                              {isDesktop && `Reported by `}
                              <Link
                                to={OmniaUrls.user(report.user)}
                                className={classNames({
                                  [styles.link__disabled]: !isUserPublic(report.user),
                                })}
                              >
                                <span className={styles.ambassadorName}>
                                  {report.user.full_name || NAME_FALLBACK}
                                </span>
                              </Link>
                              {isUserPublic(report.user) ? (
                                <>
                                  {' '}
                                  –{' '}
                                  <span
                                    className={styles.viewReport}
                                    onClick={() => {
                                      replaceQueryString({
                                        [GlobalQueryParams.report_id]: report.id.toString(),
                                      });
                                    }}
                                  >
                                    View Report
                                  </span>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className={styles.allReports}>
                <Button type="link" onClick={() => openAndScrollToFishingReportsPanel?.()}>
                  VIEW ALL REPORTS
                  {productReportsCount > 1 ? ` (${productReportsCount})` : ''}
                </Button>
              </div>
            </>
          )}
        </>
      }
    >
      {hasUserWaterbodyFishingReports ? (
        <span className={classNames(styles.pill, className)}>
          On Your {pluralize('Lake', userWaterbodiesFishingReports.length)}
          {showIcon && <DownOutlined />}
        </span>
      ) : hasNearbyFishingReports ? (
        <span className={classNames(styles.pill, className)}>
          Reported Nearby {showIcon && <DownOutlined />}
        </span>
      ) : (
        <span className={classNames(styles.pill, className)}>
          Reported in {ipState} {showIcon && <DownOutlined />}
        </span>
      )}
    </Popover>
  );
});
