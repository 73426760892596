import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAuthExpired, hasAccessToken } from '../../redux/auth';
import { fetchUserCartRebate } from '../../redux/cart';
import {
  fetchCurrentUserProfile,
  fetchUserAmbassadors,
  fetchUserOrganizations,
  fetchUserProducts,
  fetchUserWaterbodies,
} from '../../redux/user';

export const UserDataHandler = (): null => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(hasAccessToken);
  const isAuthExpired = useSelector(getIsAuthExpired);

  useEffect(() => {
    if (isLoggedIn && isAuthExpired === false) {
      dispatch(fetchCurrentUserProfile());
      dispatch(fetchUserCartRebate());
      dispatch(fetchUserProducts());
      dispatch(fetchUserWaterbodies());
      dispatch(
        fetchUserAmbassadors({
          exclude: ['brands', 'lists', 'organizations', 'species', 'waterbodies'],
        })
      );
      dispatch(fetchUserOrganizations());
    }
  }, [isLoggedIn, isAuthExpired, dispatch]);

  return null;
};
