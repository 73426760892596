import { CloseCircleOutlined } from '@ant-design/icons';
import {
  AmbassadorDetailMediaFishingReports,
  FishingReport,
  Media,
  Waterbody,
} from '@omniafishing/core';
import { Divider } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { isDone, isNotStarted, isPending, LoadingState } from '../../constants/loading_state';
import { useLocalStorage } from '../../hooks/use_local_storage';
import { usePrevious } from '../../hooks/use_previous';
import { useQueryString } from '../../hooks/use_query_string';
import { useResponsive } from '../../hooks/use_responsive';
import { useUser } from '../../hooks/use_user';
import { apiV1 } from '../../lib/api';
import { reportsWithinNumberOfDays } from '../../lib/fishing_reports';
import { StorageKeys } from '../../lib/local_storage';
import { scrollToElementTop } from '../../lib/scroll';
import { toDollars } from '../../lib/to_dollars';
import { OmniaUrls } from '../../lib/urls';
import { isAmbassador, isUserPublic, roleAsText } from '../../lib/user';
import { AuthActions } from '../../redux/auth';
import { getRebateBalance, hasRebateBalance } from '../../redux/cart';
import { FishingReportModalActions } from '../../redux/fishing_report_modal';
import { ProUpsellModalActions } from '../../redux/pro_upsell_modal';
import {
  fetchUserFishingReports,
  getFavoriteAmbassadors,
  getFavoriteAmbassadorsLoadingState,
  getUserFishingReports,
  getUserFishingReportsLoadingState,
  getUserOrganizations,
  getUserProfile,
  getUserWaterbodies,
  getUserWaterbodiesLoadingState,
} from '../../redux/user';
import { DashboardPaths, RoutePaths } from '../../routes';
import base from '../../styles/base.less';
import { ContentWrapper } from '../content_wrapper/content_wrapper';
import DashboardHeader from '../dashboard_header/dashboard_header';
import { MOBILE_HEADER_HEIGHT_APPROX } from '../header/header_mobile';
import thumbnailOmnia from '../home/video_posters/about_omnia.jpg';
import Loading from '../loading/loading';
import { MapModal } from '../map_modal/map_modal';
import { MuxPlayer } from '../muxPlayer/muxPlayer';
import { OmniaButton } from '../omnia_button/omnia_button';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';
import SvgLakes from '../svg/lakes';
import SvgReports from '../svg/reports';
import SvgUser from '../svg/user';
import { UserProfileImgRinged } from '../user_profile_img_ringed/user_profile_img_ringed';
import styles from './dashboard.less';
import { DashboardAmbassadors } from './dashboard_ambassadors';
import { DashboardUserReports } from './dashboard_user_reports';
import { DashboardWaterbodies } from './dashboard_waterbodies';

export enum DashboardTabParam {
  LAKES = 'lakes',
  AMBASSADORS = 'ambassadors',
  FISHING_REPORTS = 'fishing-reports',
}

export const DashboardParams = {
  TAB: 'tab',
} as const;

export interface WaterbodyRecentMediaAndReports {
  waterbody: Waterbody;
  fishing_reports: FishingReport[];
  media: Media[];
}

export interface FishingReportsAndMedia {
  fishing_reports: FishingReport[];
  media: Media[];
}

export const mediaWithinNumberOfDays = (media: Media[], timePeriod: number) => {
  const daysAgo = dayjs().subtract(timePeriod, 'days');
  return media.filter((m) => {
    const published =
      m.media_type === 'OmniaVideo' ? m.media_item.publication_date : m.media_item.published_at;
    return dayjs(published).isAfter(daysAgo);
  });
};

export const getRecentActivity = (entities: FishingReportsAndMedia[], timePeriod: number) => {
  return entities.filter(({ fishing_reports, media }) => {
    const newReports = reportsWithinNumberOfDays(fishing_reports, timePeriod);
    const newMedia = mediaWithinNumberOfDays(media, timePeriod);
    return newReports.length > 0 || newMedia?.length > 0;
  });
};

export const NEW_REPORTS_TIME_PERIOD = 11;
export const NEW_MEDIA_TIME_PERIOD = 11;
export const RECENT_REPORTS_TIME_PERIOD = 45;
export const RECENT_MEDIA_TIME_PERIOD = 45;

export const Dashboard = () => {
  const dispatch = useDispatch();
  const userWaterbodies = useSelector(getUserWaterbodies);
  const prevUserWaterbodies = usePrevious(userWaterbodies);
  const userFishingReports = useSelector(getUserFishingReports);
  const { user, isPro, isPremium } = useUser();
  const userOrgs = useSelector(getUserOrganizations);
  const userProfile = useSelector(getUserProfile);
  const hasRebate = useSelector(hasRebateBalance);
  const rebate = useSelector(getRebateBalance);
  const favoriteAmbassadors = useSelector(getFavoriteAmbassadors);
  const fishingReportsLoadingState = useSelector(getUserFishingReportsLoadingState);
  const userWaterbodiesLoadingState = useSelector(getUserWaterbodiesLoadingState);
  const userAmbassadorsLoadingState = useSelector(getFavoriteAmbassadorsLoadingState);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const { isDesktop } = useResponsive();

  const [waterbodiesRecentReportsAndMedia, setWaterbodiesRecentReportsAndMedia] = useState<
    WaterbodyRecentMediaAndReports[]
  >([]);
  const [ambassadorsRecentReportsAndMedia, setAmbassadorsRecentReportsAndMedia] = useState<
    AmbassadorDetailMediaFishingReports[]
  >([]);
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.NOT_STARTED);
  const [proManagementUrl, setProManagementUrl] = useState<string>();

  const { getCurrentQuery } = useQueryString();
  const currentQuery = getCurrentQuery<{ tab: string }>();
  const path = currentQuery[DashboardParams.TAB] as DashboardTabParam;

  const [dashboardVideoShow, setDashboardVideoShow] = useLocalStorage(
    StorageKeys.dashboardVideoShow,
    true
  );

  const tabsRef = useRef<HTMLDivElement>(null);

  const [tabIndex, setTabIndex] = useState(0);
  const handleSelect = (index: number) => {
    setTabIndex(index);
  };

  const onLogout = () => {
    dispatch(AuthActions.AUTH_LOGOUT());
  };

  useEffect(() => {
    if (path) {
      const indexOfTab = Object.values(DashboardTabParam).indexOf(path);
      setTabIndex(indexOfTab);
      if (tabsRef.current) {
        scrollToElementTop(tabsRef.current, MOBILE_HEADER_HEIGHT_APPROX);
      }
    }
  }, [path, tabsRef.current]);

  const fetchWaterbodiesLatestReportsAndMedia = async () => {
    const waterbodiesLatestFishingReportsRes = await apiV1.userWaterbodiesFetchLatest({
      since: dayjs().subtract(RECENT_REPORTS_TIME_PERIOD, 'days').format('YYYY-MM-DD'),
      waterbodies: userWaterbodies.map((waterbody) => waterbody.id),
    });
    const waterbodyMediaFetches = userWaterbodies.map((waterbody) =>
      apiV1.mediaFetch({
        waterbodies: [waterbody.url_slug],
        since: dayjs().subtract(RECENT_REPORTS_TIME_PERIOD, 'days').format('YYYY-MM-DD'),
        per_page: 50,
      })
    );

    const waterbodyMediaResponses = await Promise.allSettled(waterbodyMediaFetches);
    const waterbodiesMediaAndSlug = waterbodyMediaResponses.reduce((acc, response, index) => {
      if (response.status === 'fulfilled') {
        const waterbodySlug = userWaterbodies[index].url_slug;
        acc[waterbodySlug] = response.value.data.data;
      }
      return acc;
    }, {} as { [key: string]: Media[] });

    return waterbodiesLatestFishingReportsRes.data.data.map(({ waterbody, fishing_reports }) => {
      return {
        waterbody,
        fishing_reports,
        media: waterbodiesMediaAndSlug[waterbody.url_slug],
      };
    });
  };

  const fetchPageData = async () => {
    setLoadingState(LoadingState.PENDING);
    try {
      let waterbodyRecentReportsAndMedia = [] as WaterbodyRecentMediaAndReports[];
      if (userWaterbodies.length > 0) {
        waterbodyRecentReportsAndMedia = await fetchWaterbodiesLatestReportsAndMedia();
      }

      let ambassadorMediaFrs = [] as AmbassadorDetailMediaFishingReports[];
      if (favoriteAmbassadors.length > 0) {
        const ambassadorsRes = await apiV1.userAmbassadorsFetchLatest({
          since: dayjs().subtract(RECENT_MEDIA_TIME_PERIOD, 'days').format('YYYY-MM-DD'),
          ambassadors: favoriteAmbassadors.map((ambassador) => ambassador.id.toString()),
        });
        ambassadorMediaFrs = ambassadorsRes.data.data;
      }
      setLoadingState(LoadingState.DONE);
      setWaterbodiesRecentReportsAndMedia(waterbodyRecentReportsAndMedia);
      setAmbassadorsRecentReportsAndMedia(ambassadorMediaFrs);
    } catch (e) {
      console.error(e);
      setLoadingState(LoadingState.ERROR);
    }
  };

  useEffect(() => {
    if (isDone(userWaterbodiesLoadingState) && isDone(userAmbassadorsLoadingState)) {
      fetchPageData();
    }
  }, [userWaterbodiesLoadingState, userAmbassadorsLoadingState]);

  useEffect(() => {
    // this should move to UserDataHandler if it has any use outside this page
    dispatch(fetchUserFishingReports());
  }, []);

  useEffect(() => {
    if (isPro) {
      apiV1.userProManagementFetch().then((res) => {
        setProManagementUrl(res.data.data.management_url);
      });
    }
  }, [isPro]);

  const showPublicLink = user && userProfile && isUserPublic(user);

  const userTitles = [] as string[];

  if (isPro) {
    userTitles.push('Omnia PRO Member');
  } else if (isPremium) {
    userTitles.push('Premium Member');
  }

  if (userProfile?.title) {
    userTitles.push(userProfile.title);
  }

  const waterbodiesWithRecentWeekActivity =
    (waterbodiesRecentReportsAndMedia &&
      getRecentActivity(waterbodiesRecentReportsAndMedia, NEW_REPORTS_TIME_PERIOD)) ||
    [];
  const ambassadorsWithRecentWeekActivity =
    (ambassadorsRecentReportsAndMedia &&
      getRecentActivity(ambassadorsRecentReportsAndMedia, NEW_REPORTS_TIME_PERIOD)) ||
    [];
  const waterbodiesHaveRecentWeekActivity = waterbodiesWithRecentWeekActivity.length > 0;
  const ambassadorsHaveRecentWeekActivity = ambassadorsWithRecentWeekActivity.length > 0;

  return (
    <div className={styles.background}>
      <section className={classNames(styles.userOverview)}>
        <ContentWrapper>
          <DashboardHeader title="Dashboard" />
          <div className={styles.top}>
            <div className={styles.userInfo}>
              <div className={styles.avatar}>
                <UserProfileImgRinged
                  role={user?.role}
                  image={userProfile?.image}
                  imageSize={100}
                  size={64}
                  borderSize={3}
                />
              </div>
              <div className={styles.profile}>
                <div>
                  <div className={styles.profileItemList}>
                    <div>
                      <p className={styles.name}>{userProfile?.user?.full_name}</p>
                      {userTitles.length > 0 && (
                        <p className={styles.titles}>{userTitles.join(', ')}</p>
                      )}
                      {isAmbassador(user) && (
                        <p className={styles.role}>Omnia Ambassador {roleAsText(user?.role)}</p>
                      )}
                    </div>
                    {!isPro && (
                      <OmniaButton
                        onPress={() => dispatch(ProUpsellModalActions.PRO_UPSELL_MODAL_OPEN())}
                        size="lg"
                        kind="primary"
                        className={styles.upgradeButton}
                      >
                        Upgrade to PRO
                      </OmniaButton>
                    )}
                  </div>
                  <div className={styles.profileActions}>
                    {showPublicLink && (
                      <>
                        <Link to={OmniaUrls.user(user)}>View Profile</Link>
                        <Divider type="vertical" className={styles.divider__dark} />{' '}
                      </>
                    )}
                    <Link to={`${RoutePaths.DASHBOARD}/${DashboardPaths.PROFILE_EDIT}`}>
                      Edit Profile
                    </Link>
                    <Divider type="vertical" className={styles.divider__dark} />{' '}
                    <span className={base.link} onClick={onLogout}>
                      Log out
                    </span>
                  </div>
                </div>

                <div className={styles.userSpecies}>
                  <strong>MY SPECIES</strong>{' '}
                  {userProfile?.species.map((specie) => specie.display_name).join(', ')}
                  <Divider type="vertical" className={styles.divider__dark} />
                  <Link to={`${RoutePaths.DASHBOARD}/${DashboardPaths.PROFILE_EDIT}`}>
                    <strong>Edit Profile</strong>
                  </Link>
                </div>

                <div className={styles.userGroups}>
                  <strong>MY GROUPS </strong>
                  {userOrgs.map((org, index) => (
                    <React.Fragment key={org.slug}>
                      {index > 0 && ', '}
                      <Link to={OmniaUrls.organization(org)}>{org.name}</Link>
                    </React.Fragment>
                  ))}
                  <Divider type="vertical" className={styles.divider__dark} />
                  <Link to={`${RoutePaths.DASHBOARD}/${DashboardPaths.GROUPS}`}>
                    <strong>Edit Groups</strong>
                  </Link>
                </div>

                {(isPremium || isPro) && (
                  <div className={styles.premiumExpires}>
                    <p>
                      {isPro ? 'PRO' : 'Premium'} Member until{' '}
                      {dayjs(isPro ? user.premium_pro_expires_at : user.premium_expires_at).format(
                        'MMM D, YYYY'
                      )}
                      {isPro && (
                        <>
                          {proManagementUrl && (
                            <a
                              href={proManagementUrl}
                              style={{ marginLeft: '0.5em', fontWeight: 'bold' }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Manage PRO subscription
                            </a>
                          )}
                        </>
                      )}
                    </p>
                  </div>
                )}
              </div>
            </div>

            {hasRebate && (
              <div className={styles.rewards}>
                <p className={styles.rewardsHeading}>My Omnia credits</p>
                <p className={styles.rewardsTotal}>
                  <span className={styles.totalCredit}>Total Credit:</span> {toDollars(rebate)}
                </p>
              </div>
            )}
          </div>
        </ContentWrapper>
      </section>
      <div className={styles.lowerSectionBackground}>
        <ContentWrapper className={styles.lowerSection}>
          {dashboardVideoShow && (
            <section className={styles.fishingReportsVideo}>
              <span
                className={styles.fishingReportsVideoClose}
                onClick={() => {
                  setDashboardVideoShow(false);
                }}
              >
                <CloseCircleOutlined /> Hide video
              </span>
              <MuxPlayer
                playbackId={'gAaTZJFzXef002kHoMxY8HF02R8HD4QRE4svgJzZ5oh5E'}
                thumbnail={thumbnailOmnia}
              />
            </section>
          )}
          <div ref={tabsRef}>
            <Tabs
              forceRenderTabPanel
              selectedTabClassName={styles.tab__active}
              selectedTabPanelClassName={styles.tabPanel__active}
              disabledTabClassName={styles.tab__disabled}
              selectedIndex={tabIndex}
              onSelect={handleSelect}
            >
              <TabList className={styles.tabs}>
                <Tab className={styles.tab}>
                  <h3 className={styles.tabHeading}>
                    {isDesktop ? 'Lakes You Fish ' : 'Lakes'}
                    {waterbodiesHaveRecentWeekActivity && (
                      <span className={styles.redCircle}></span>
                    )}
                  </h3>
                </Tab>
                <Tab className={styles.tab}>
                  <h3 className={styles.tabHeading}>
                    {isDesktop ? 'Your Ambassadors ' : 'Ambassadors'}
                    {ambassadorsHaveRecentWeekActivity && (
                      <span className={styles.redCircle}></span>
                    )}
                  </h3>
                </Tab>
                <Tab className={styles.tab}>
                  <h3 className={styles.tabHeading}>Your Fishing Reports</h3>
                </Tab>
              </TabList>
              <TabPanel className={styles.tabPanel}>
                <ContentWrapper className={styles.tabContent}>
                  {isPending(loadingState) || isNotStarted(loadingState) ? (
                    <Loading text="Loading Your Lakes" className={styles.loading} />
                  ) : waterbodiesRecentReportsAndMedia.length === 0 && isDone(loadingState) ? (
                    <>
                      <div className={styles.emptyItems}>
                        <div>
                          <SvgLakes strokeWidth={0.8} />
                        </div>
                        <p>
                          You Don’t Have Any Favorite Lakes <br />{' '}
                          <span>
                            Select your favorite lakes for notifications on the latest fishing
                            reports, articles, and videos.
                          </span>
                        </p>

                        <OmniaButton
                          onClick={() => setIsMapModalOpen(true)}
                          kind="primary"
                          size="lg"
                          fontSize={14}
                        >
                          Add A Favorite Lake
                        </OmniaButton>
                      </div>
                    </>
                  ) : (
                    <DashboardWaterbodies
                      waterbodiesRecentReportsAndMedia={waterbodiesRecentReportsAndMedia}
                      onNewLakeClick={() => setIsMapModalOpen(true)}
                    />
                  )}
                </ContentWrapper>
              </TabPanel>
              <TabPanel className={styles.tabPanel}>
                <ContentWrapper className={styles.tabContent}>
                  {isPending(loadingState) || isNotStarted(loadingState) ? (
                    <Loading text="Loading Your Favorite Ambassadors" className={styles.loading} />
                  ) : ambassadorsRecentReportsAndMedia.length === 0 && isDone(loadingState) ? (
                    <div className={styles.emptyItems}>
                      <div>
                        <SvgUser />
                      </div>
                      <p>
                        You Aren’t Following Any Ambassadors <br />{' '}
                        <span>
                          Follow ambassadors for updates on their latest fishing reports, articles,
                          and video content.
                        </span>
                      </p>
                      <OmniaLinkButton
                        to={RoutePaths.AMBASSADORS}
                        kind="primary"
                        size="lg"
                        fontSize={14}
                      >
                        Follow an Ambassador
                      </OmniaLinkButton>
                    </div>
                  ) : (
                    <DashboardAmbassadors
                      ambassadorsRecentReportsAndMedia={ambassadorsRecentReportsAndMedia}
                    />
                  )}
                </ContentWrapper>
              </TabPanel>
              <TabPanel className={styles.tabPanel}>
                <ContentWrapper className={styles.tabContent}>
                  {isPending(fishingReportsLoadingState) ||
                  isNotStarted(fishingReportsLoadingState) ? (
                    <Loading text="Loading Your Fishing Reports" className={styles.loading} />
                  ) : userFishingReports.length === 0 && isDone(fishingReportsLoadingState) ? (
                    <div className={styles.emptyItems}>
                      <div>
                        <span style={{ display: 'block', marginLeft: '3px' }}>
                          <SvgReports strokeWidth={1.3} />
                        </span>
                      </div>
                      <p>
                        You Haven't Filed a Fishing Report
                        <br />
                        <span>
                          File a report to let others know what technique and bait worked for you.
                        </span>
                      </p>

                      <OmniaButton
                        onClick={() =>
                          dispatch(FishingReportModalActions.FISHING_REPORT_MODAL_OPEN())
                        }
                        kind="primary"
                        size="lg"
                        fontSize={14}
                      >
                        File A Fishing Report
                      </OmniaButton>
                    </div>
                  ) : (
                    <DashboardUserReports />
                  )}
                </ContentWrapper>
              </TabPanel>
            </Tabs>
          </div>
        </ContentWrapper>
      </div>
      <MapModal
        width={'min(97%, 1384px)'}
        centered={isDesktop}
        geoCoderExpanded
        showSatelliteToggle
        replaceFileReportWithFollowButton
        geoCoderFocusAndOpenOnMount
        open={isMapModalOpen}
        onCancel={async () => {
          const userWaterbodiesHasChanged =
            prevUserWaterbodies &&
            prevUserWaterbodies.map((w) => w.id).join('') !==
              userWaterbodies.map((w) => w.id).join('');
          setLoadingState(LoadingState.PENDING);
          setIsMapModalOpen(false);
          if (userWaterbodies.length && userWaterbodiesHasChanged) {
            const waterbodyRecentReportsAndMedia = await fetchWaterbodiesLatestReportsAndMedia();
            setWaterbodiesRecentReportsAndMedia(waterbodyRecentReportsAndMedia);
          }
          setLoadingState(LoadingState.DONE);
        }}
      />
    </div>
  );
};
