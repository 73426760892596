import React from 'react';

const SvgLure = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <path
        d="M17.131 13.167l2.672 2.672c.512.512.903.512 1.415 0 .511-.512.511-.903 0-1.414a.5.5 0 0 1 .707-.707c.902.902.902 1.926 0 2.828-.71.709-1.493.86-2.234.456-.067.394-.285.785-.655 1.155-.903.902-1.927.902-2.829 0a.5.5 0 1 1 .707-.707c.512.512.903.512 1.414 0 .512-.512.512-.903 0-1.414l-2.741-2.742-7.694.635 2.91 2.91c.512.512.903.512 1.415 0 .511-.512.511-.903 0-1.414a.5.5 0 0 1 .707-.707c.902.902.902 1.926 0 2.828-.71.709-1.493.86-2.234.456-.067.394-.285.785-.655 1.155-.903.902-1.927.902-2.829 0a.5.5 0 1 1 .707-.707c.512.512.903.512 1.414 0 .512-.512.512-.903 0-1.414l-2.98-2.98-3.724.307a1.5 1.5 0 0 1-1.521-2.04l.804-2.063a1.5 1.5 0 0 0 .022-1.029l-.766-2.246a1.5 1.5 0 0 1 1.89-1.91l15.424 5.09a1.5 1.5 0 0 1-.347 2.92l-.999.082zm-2.104-.83a.5.5 0 0 1 .826-.19l.113.112 2.082-.171a.5.5 0 0 0 .115-.974L2.74 6.026a.5.5 0 0 0-.63.636l.766 2.247a2.5 2.5 0 0 1-.036 1.714l-.804 2.064a.5.5 0 0 0 .507.68l3.025-.25.034-.03c1.22-.993 1.816-2.185 1.816-3.613a.5.5 0 0 1 1 0c0 1.33-.433 2.507-1.286 3.514l2.318-.191a.501.501 0 0 1 .059-.11c.414-.59.623-1.323.623-2.213a.5.5 0 1 1 1 0c0 .833-.156 1.576-.472 2.223l1.92-.158c.129-.293.195-.647.195-1.065a.5.5 0 1 1 1 0c0 .35-.037.675-.112.975l1.365-.112zM4.917 9.474a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
        id="Lure-a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="Lure-b" fill="#fff">
        <use xlinkHref="#Lure-a" />
      </mask>
      <g mask="url(#Lure-b)" fill="currentColor">
        <rect width={24} height={24} rx={4} />
      </g>
    </g>
  </svg>
);

export default SvgLure;
