import { AlgoliaTechnique } from '@omniafishing/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../routes';
import styles from './search_style.less';

interface SearchStyleProps {
  style: AlgoliaTechnique;
  onClick: (target_url?: string) => void;
}

export const SearchStyle = (props: SearchStyleProps) => {
  const { style, onClick } = props;

  return (
    <Link
      to={`${RoutePaths.TECHNIQUES}/${style.url_path}`}
      className={styles.link}
      onClick={() => onClick(`${RoutePaths.TECHNIQUES}/${style.url_path}`)}
    >
      <span
        className={styles.name}
        dangerouslySetInnerHTML={{ __html: style._highlightResult.display_name.value }}
      />
    </Link>
  );
};
