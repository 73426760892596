import { EventNotificationFrequencies, Waterbody, WaterbodyDetail } from '@omniafishing/core';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingState } from '../constants/loading_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import { WebAnalytics } from '../lib/web_analytics';
import { FlashMessageActions } from '../redux/flash_message';
import { getUser, UserActions } from '../redux/user';
import { WaterbodyActions } from '../redux/waterbody';

interface EventNotifications {
  frequency: EventNotificationFrequencies;
  new_fishing_report: boolean;
  new_article: boolean;
}

interface FavoriteWaterbodyArgs {
  event_notifications: EventNotifications;
  email?: string;
  onCompletion?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useWaterbodyFavorite = (waterbody: Waterbody | WaterbodyDetail | null) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const url_slug = waterbody?.url_slug;
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.NOT_STARTED);

  const favoriteWaterbody = (args: FavoriteWaterbodyArgs) => {
    const { event_notifications, email, onCompletion, onSuccess, onError } = args;

    if (!user && !email) {
      return;
    }
    setLoadingState(LoadingState.PENDING);
    apiV1
      .waterbodyFavorite(url_slug, event_notifications, user?.email || email)
      .then((res) => {
        setLoadingState(LoadingState.DONE);
        dispatch(WaterbodyActions.WATERBODY_FAVORITE_SUCCESS(res.data));
        WebAnalytics.itemFavorited('waterbody', url_slug);

        dispatch(
          FlashMessageActions.FLASH_MESSAGE_SET({
            header: 'Success!',
            subheader: `You subscribed to ${waterbody.primary_name} fishing report updates`,
          })
        );
        onSuccess?.();
      })
      .catch((err) => {
        setLoadingState(LoadingState.ERROR);
        errorHandler(`ERROR: favoriteWaterbody: ${url_slug}`, err);
        onError?.();
      })
      .finally(() => {
        onCompletion?.();
      });
  };

  const updateEventNotifications = (args: FavoriteWaterbodyArgs) => {
    const { event_notifications, email, onCompletion, onSuccess, onError } = args;
    apiV1
      .waterbodyUpdateNotifications(url_slug, event_notifications, email)
      .then((res) => {
        setLoadingState(LoadingState.DONE);
        dispatch(UserActions.USER_WATERBODY_EVENT_NOTIFICATIONS_UPDATE_SUCCESS(res.data));
        WebAnalytics.itemFavorited('waterbody', url_slug);

        dispatch(
          FlashMessageActions.FLASH_MESSAGE_SET({
            header: 'Success!',
            subheader: `You updated your notifications for ${waterbody.primary_name}`,
          })
        );
        onCompletion?.();
        onSuccess?.();
      })
      .catch((err) => {
        setLoadingState(LoadingState.ERROR);
        errorHandler(`ERROR: favoriteWaterbody: ${url_slug}`, err);
        onCompletion?.();
        onError?.();
      });
  };

  const unfavoriteWaterbody = (
    args: {
      onCompletion?: () => void;
      onSuccess?: () => void;
      onError?: () => void;
    } = {}
  ) => {
    const { onCompletion, onSuccess, onError } = args;
    setLoadingState(LoadingState.PENDING);
    apiV1
      .waterbodyUnfavorite(url_slug)
      .then((res) => {
        setLoadingState(LoadingState.DONE);
        dispatch(WaterbodyActions.WATERBODY_UNFAVORITE_SUCCESS(res.data));
        dispatch(
          FlashMessageActions.FLASH_MESSAGE_SET({
            header: 'Complete',
            subheader: `You unsubscribed to ${waterbody.primary_name} fishing report updates`,
            type: 'warning',
          })
        );
        onSuccess?.();
      })
      .catch((err) => {
        setLoadingState(LoadingState.ERROR);
        errorHandler(`ERROR: UnfavoriteWaterbody: ${url_slug}`, err);
        onError?.();
      })
      .then(() => {
        onCompletion?.();
      });
  };

  return { loadingState, favoriteWaterbody, unfavoriteWaterbody, updateEventNotifications };
};
