import { FishingReport } from '@omniafishing/core';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryString } from '../../hooks/use_query_string';
import { OmniaUrls } from '../../lib/urls';
import { WebAnalytics } from '../../lib/web_analytics';
import { FlashMessageActions } from '../../redux/flash_message';
import { getBaseUrl } from '../../redux/router';
import { SvgShare } from '../svg/share';
import styles from './share_report_button.less';

interface ShareButtonCondensedProps {
  fishingReport: FishingReport;
  onShareClick?: () => void;
  className?: string;
}

export const ShareReportButton = (props: ShareButtonCondensedProps) => {
  const { fishingReport, onShareClick, className } = props;
  const { comments, created_at, user, outing_date, species, waterbody } = fishingReport;

  const baseUrl = useSelector(getBaseUrl);
  const { stringify } = useQueryString();

  const dispatch = useDispatch();
  const utmTags = stringify({
    utm_source: 'om-social',
    utm_medium: 'share-button',
    utm_campaign: 'fishing-reports',
    utm_content: fishingReport.id,
  });

  const fullFishingReportUrl = `${baseUrl}${OmniaUrls.fishingReport(fishingReport)}?${utmTags}`;
  const share_title = (() => {
    const { full_name } = user;
    let userName = '';
    if (full_name) {
      userName = `${full_name}'s `;
    }
    const date = outing_date || created_at;
    return `${userName}${waterbody.primary_name} Fishing Report for ${
      species.display_name
    } (${dayjs(date).format('MMM D, YYYY')})`;
  })();

  const shareData = {
    url: fullFishingReportUrl,
    // text and title are shared inconsistently, hiding for now
    // text: comments ?? '',
    // title: share_title,
  };

  return (
    <>
      {typeof navigator !== 'undefined' && navigator.canShare?.(shareData) ? (
        <button
          className={classNames(styles.shareButton, className)}
          onClick={() => {
            navigator.share(shareData).then(() => {
              WebAnalytics.socialShare({
                location: 'report',
                type: 'api',
                share_url: fullFishingReportUrl,
                share_text: comments,
                share_title,
              });
            });
            onShareClick?.();
          }}
        >
          <SvgShare />
        </button>
      ) : (
        <CopyToClipboard
          text={fullFishingReportUrl}
          onCopy={() => {
            dispatch(
              FlashMessageActions.FLASH_MESSAGE_SET({
                header: 'Link Copied',
                subheader:
                  'A link to this fishing report has been copied. Share this link with your friends and family.',
              })
            );
            WebAnalytics.socialShare({
              location: 'report',
              type: 'link',
              share_url: fullFishingReportUrl,
              share_text: comments,
              share_title,
            });
            onShareClick?.();
          }}
        >
          <button className={styles.shareButton}>
            <SvgShare />
          </button>
        </CopyToClipboard>
      )}
    </>
  );
};
