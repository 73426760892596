import { ProductFamily } from '@omniafishing/core';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useListViewed, WebAnalyticsListViewedType } from '../../hooks/use_list_viewed';
import { trackJs } from '../../lib/trackjs';
import { WebAnalytics } from '../../lib/web_analytics';
import OmniaCarousel from './omnia_carousel';

interface ProductCarouselProps {
  adaptive?: boolean;
  arrows: boolean;
  children: React.ReactNode;
  fadedEdges?: boolean;
  list_id_title?: string;
  list_id_type?: WebAnalyticsListViewedType;
  list_uuid: string;
  products_families: ProductFamily[];
}

export const ProductFamilyCarousel = (props: ProductCarouselProps) => {
  const {
    adaptive = true,
    arrows,
    children,
    fadedEdges = true,
    list_id_title,
    list_id_type,
    list_uuid,
    products_families,
  } = props;
  const handles = products_families.map((pf) => pf.handle);
  const handlesString = handles.join();

  const { coupon, createListId } = useListViewed();

  const id_type = list_id_type ?? 'carousel';

  const childrenKeys = _.flatten(
    React.Children.map(children, (child) => {
      if (child.hasOwnProperty('key')) {
        return (child as JSX.Element).key;
      }
    })
  );
  const hasDuplicateKeys = _.uniq(childrenKeys).length !== childrenKeys.length;
  if (hasDuplicateKeys) {
    trackJs('Duplicate ProductFamilyCarousel keys found');
  }

  useEffect(() => {
    WebAnalytics.productFamilyListViewed({
      productFamilies: products_families,
      coupon,
      list_id: createListId(id_type, list_id_title),
      list_uuid,
    });
  }, [handlesString]);

  return (
    <OmniaCarousel adaptive={adaptive} arrows={arrows} fadedEdges={fadedEdges} key={handlesString}>
      {children}
    </OmniaCarousel>
  );
};
