import { LoadingOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Organization, SignupParams, WaterbodyDetail } from '@omniafishing/core';
import { Form, Input } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { isDone, isPending } from '../../constants/loading_state';
import { useProPurchaseUrl } from '../../hooks/use_pro_purchase_url';
import { useQueryString } from '../../hooks/use_query_string';
import { useRudderstack } from '../../hooks/use_rudderstack';
import { useUserCampaignInfo } from '../../hooks/use_user_campaign_info';
import { apiV1 } from '../../lib/api';
import { OmniaQueryParams } from '../../lib/query_string';
import { proPaymentLinkUrls, proTrialPaymentLinkUrls } from '../../lib/stripe';
import { getError, getLoadingState, signupUser } from '../../redux/auth';
import { getIpState } from '../../redux/geographic_location';
import { getExperimentUuid } from '../../redux/user';
import { RoutePaths } from '../../routes';
import utilities from '../../styles/utilities.less';
import AuthFormError from '../auth_form_wrapper/auth_form_error';
import AuthFormWrapper from '../auth_form_wrapper/auth_form_wrapper';
import { OmniaButton } from '../omnia_button/omnia_button';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';
import styles from './signup_form.less';

interface SignupFormProps {
  cardClassName?: string;
  heading?: ReactNode;
  forwardRoute?: string;
  onLoginClick?: () => void;
  renderForm?: boolean;
}

export const SignupForm = (props: SignupFormProps) => {
  const { onLoginClick, heading, cardClassName, forwardRoute, renderForm = true } = props;
  const formFields = {
    username: 'username',
    password: 'password',
    organization_registration_code: 'registration_code',
  };

  const loadingState = useSelector(getLoadingState);
  const error = useSelector(getError);
  const ipState = useSelector(getIpState);
  const history = useHistory();
  const location =
    useLocation<{ from: string; species_name: string; waterbody: WaterbodyDetail }>();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { getCurrentQuery } = useQueryString();
  const queryParams = getCurrentQuery<{
    group: string;
    email: string;
  }>();
  const orgRegistrationCode = queryParams.group;
  const [orgToJoin, setOrgToJoin] = useState<Organization>();
  const userEmail = queryParams.email;
  const experimentUuid = useSelector(getExperimentUuid);
  const { anonymousId } = useRudderstack();
  const userCampaignInfo = useUserCampaignInfo(location?.state?.from);
  const { proPurchaseUrl } = useProPurchaseUrl({
    trial: proTrialPaymentLinkUrls.includes(forwardRoute),
    purchaseUrl: forwardRoute,
    trialPurchaseUrl: forwardRoute,
  });
  const fromPath = location.state?.from || RoutePaths.HOME;
  const [email, setEmail] = useState('');

  useEffect(() => {
    // loading state gets reset on signup because the auth token remounts the whole app
    if (isDone(loadingState)) {
      const waterbody = location.state?.waterbody;
      const species_name = location.state?.species_name;
      let search = null as string;
      if (species_name) {
        search = `?${OmniaQueryParams.species}=${species_name}`;
      }

      if (fromPath === RoutePaths.AMBASSADOR_APPLICATION_FORM) {
        history.push({
          pathname: RoutePaths.AMBASSADOR_APPLICATION_FORM,
          state: { from: fromPath },
        });
      } else if (forwardRoute) {
        // Any logic for the pro purchase urls should also be added to the login form
        if (proPaymentLinkUrls.includes(forwardRoute)) {
          window.location.href = proPurchaseUrl;
        } else {
          window.location.href = forwardRoute;
        }
      } else {
        history.push({
          pathname: RoutePaths.SIGNUP_FOLLOWUP,
          state: { from: fromPath, waterbody },
          search,
        });
      }
    }
  }, [loadingState]);

  useEffect(() => {
    if (orgRegistrationCode) {
      apiV1.organizationsFetch({ registration_code: orgRegistrationCode }).then((res) => {
        setOrgToJoin(res.data.data[0]);
      });
    }
  }, [orgRegistrationCode]);

  const handleSubmit = (values: any) => {
    setEmail(values[formFields.username]);
    const params: SignupParams = {
      username: values[formFields.username],
      password: values[formFields.password],
      organization_registration_code: values[formFields.organization_registration_code],
      ip_state: ipState,
      experiment_group_id: 0,
      experiment_uuid: experimentUuid,
      anonymous_id: anonymousId,
      ...userCampaignInfo,
    };
    dispatch(signupUser(params));
  };

  const isSubmitting = isPending(loadingState);

  let message: ReactNode;
  if (error === 'USERNAME_EXISTS') {
    message = (
      <>
        An account with this email already exists.{' '}
        <Link
          to={{
            search: `?email=${email}`,
            pathname: RoutePaths.LOGIN,
            state: { from: fromPath },
          }}
        >
          Login
        </Link>
      </>
    );
  }
  if (error === 'ORG_REG_CODE_NOT_FOUND') {
    message = <span>Organization not found. Please check your Organization code.</span>;
  }
  if (error === 'PARAMETER_ERROR') {
    message = (
      <>
        Username or Password invalid. <br /> Please try again.
      </>
    );
  }

  if (!renderForm) {
    return null;
  }

  return (
    <AuthFormWrapper className={styles.cardContainer} cardClassName={cardClassName || styles.card}>
      <AuthFormError errorMessage={message} />
      {heading ? (
        heading
      ) : (
        <>
          <h1 className={styles.heading}> Personalized Tackle Shopping</h1>
          <p className={'text-center mb-4'}>Create your profile based on the lakes you fish.</p>
        </>
      )}
      <Form
        layout="vertical"
        className={styles.form}
        onFinish={handleSubmit}
        onFinishFailed={(e) => {
          console.log(e);
        }}
        form={form}
        initialValues={{
          [formFields.organization_registration_code]: orgRegistrationCode,
          [formFields.username]: userEmail,
        }}
        validateTrigger="onBlur"
      >
        <div className={styles.col1}>
          <Form.Item
            name={formFields.username}
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid email',
              },
              {
                required: true,
                message: 'Please enter your email',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
              type="email"
              size="large"
            />
          </Form.Item>

          <Form.Item
            name={formFields.password}
            rules={[
              {
                required: true,
                message: 'Please enter your password',
              },
              {
                min: 6,
                message: 'Passwords must be 6 characters',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Password"
              type="password"
              size="large"
            />
          </Form.Item>
        </div>

        <Form.Item name={formFields.organization_registration_code} noStyle>
          <Input type="hidden" />
        </Form.Item>

        <small style={{ marginBottom: '24px' }}>
          By creating an account, you agree to our{' '}
          <Link to={RoutePaths.TERMS}>Terms of Service</Link>
        </small>

        <div className={styles.col1}>
          <OmniaButton
            kind="secondary"
            size="lg"
            type="submit"
            block
            style={{ marginBottom: 12 }}
            isDisabled={isSubmitting}
          >
            Create Account {isSubmitting ? <LoadingOutlined /> : ''}
          </OmniaButton>

          {orgToJoin != null && (
            <small className={styles.joinGroupText}>* and join group {orgToJoin.name}</small>
          )}

          <div className={utilities.textCenter} style={{ display: 'block', marginBottom: 12 }}>
            OR{' '}
          </div>
          {onLoginClick ? (
            <OmniaButton onPress={onLoginClick} block fontSize={14} size="lg" kind="tertiary">
              Already Have an Account? Log in
            </OmniaButton>
          ) : (
            <OmniaLinkButton
              to={{
                pathname: RoutePaths.LOGIN,
                state: { from: fromPath },
              }}
              block
              fontSize={14}
              size="lg"
              kind="tertiary"
            >
              Already Have an Account? Log in
            </OmniaLinkButton>
          )}
        </div>
      </Form>
    </AuthFormWrapper>
  );
};
