import { CalendarOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import React from 'react';
import { DateOrTimeInput, formatToIsoDateZ, formatToPublishedDate } from '../../lib/time';
import styles from './published_dates.less';

interface PublishedDatesProps {
  firstPublishedDate: DateOrTimeInput;
  lastUpdatedDate?: DateOrTimeInput;
  className?: string;
  showIcon?: boolean;
}

export const PublishedDates = (props: PublishedDatesProps) => {
  const { className, firstPublishedDate, lastUpdatedDate, showIcon } = props;
  return (
    <p className={classNames(styles.publishDates, className, 'mb-4')}>
      {showIcon && <CalendarOutlined />}
      <span className={styles.published}>
        <strong>Published:</strong>{' '}
        <time dateTime={formatToIsoDateZ(firstPublishedDate)}>
          {formatToPublishedDate(firstPublishedDate)}
        </time>
      </span>
      {lastUpdatedDate && (
        <i className={styles.updated}>
          <strong>Updated:</strong>{' '}
          <time dateTime={formatToIsoDateZ(lastUpdatedDate)}>
            {formatToPublishedDate(lastUpdatedDate)}
          </time>
        </i>
      )}
    </p>
  );
};
