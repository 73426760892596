import {
  AmbassadorDetail,
  FishingReport,
  List,
  OmniaResponse,
  OmniaResponseTimestamped,
  RecommendedProductFamily,
  Tournament,
  WaterbodyDetail,
  WaterbodyEventNotifications,
  WaterbodyTechnique,
} from '@omniafishing/core';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import getNow from '../lib/time';
import { ActionsUnion, createAction } from './actions_helper';
import { RecommendationsActions } from './recommendations';
import { UserActions } from './user';

export const reducerName = 'waterbody';

export enum StateKeys {
  waterbody = 'waterbody',
  waterbodyLoadingState = 'waterbodyLoadingState',
  recommendations = 'recommendations',
  recommendationsTimestamp = 'recommendationsTimestamp',
  recommendationsLoadingState = 'recommendationsLoadingState',
  recommendationsCount = 'recommendationsCount',
  activationLoadingState = 'activationLoadingState',
  listsLoadingState = 'listsLoadingState',
  lists = 'lists',
  ambassadors = 'ambassadors',
  fishingReports = 'fishingReports',
  fishingReportsLoadingState = 'fishingReportsLoadingState',
  waterbodyTechniques = 'waterbodyTechniques',
  eventNotificationLoadingState = 'eventNotificationLoadingState',
  favoriteLoadingState = 'favoriteLoadingState',
  tournaments = 'tournaments',
}

export const initialState = {
  [StateKeys.waterbodyLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.waterbody]: null as WaterbodyDetail,
  [StateKeys.recommendations]: [] as RecommendedProductFamily[],
  [StateKeys.recommendationsTimestamp]: 0,
  [StateKeys.recommendationsLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.recommendationsCount]: null as number,
  [StateKeys.activationLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.listsLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.lists]: [] as List[],
  [StateKeys.ambassadors]: [] as AmbassadorDetail[],
  [StateKeys.fishingReports]: [] as FishingReport[],
  [StateKeys.fishingReportsLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.waterbodyTechniques]: [] as WaterbodyTechnique[],
  [StateKeys.eventNotificationLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.favoriteLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.tournaments]: [] as Tournament[],
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getWaterbodyLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.waterbodyLoadingState];
export const getWaterbody = (state: ApplicationState) => state[reducerName][StateKeys.waterbody];
export const getRecommendationsLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.recommendationsLoadingState];
export const getRecommendations = (state: ApplicationState) =>
  state[reducerName][StateKeys.recommendations];
export const getRecommendationsCount = (state: ApplicationState) =>
  state[reducerName][StateKeys.recommendationsCount];
export const getActivationLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.activationLoadingState];
export const getListsLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.listsLoadingState];
export const getLists = (state: ApplicationState) => state[reducerName][StateKeys.lists];
export const getAmbassadors = (state: ApplicationState) =>
  state[reducerName][StateKeys.ambassadors];
export const getWaterbodyTechniques = (state: ApplicationState) =>
  state[reducerName][StateKeys.waterbodyTechniques];
export const getEventNotificationLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.eventNotificationLoadingState];
export const getFavoriteLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.favoriteLoadingState];
export const getTournaments = (state: ApplicationState) =>
  state[reducerName][StateKeys.tournaments];

// ========================================================================== //
// Reducers
// ========================================================================== //

export default function waterbodyReducer(
  state = initialState,
  action: WaterbodyActions | UserActions | RecommendationsActions
) {
  switch (action.type) {
    case ReduxActions.WATERBODY_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.waterbodyLoadingState]: LoadingState.PENDING,
      };

    case ReduxActions.WATERBODY_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.waterbodyLoadingState]: LoadingState.DONE,
        [StateKeys.waterbody]: action.payload.data,
      };

    case ReduxActions.WATERBODY_FAVORITE_PENDING:
      return {
        ...state,
        [StateKeys.favoriteLoadingState]: LoadingState.PENDING,
      };

    case ReduxActions.WATERBODY_FAVORITE_SUCCESS:
      return {
        ...state,
        [StateKeys.favoriteLoadingState]: LoadingState.DONE,
        [StateKeys.waterbody]: action.payload.data,
      };

    case ReduxActions.WATERBODY_UNFAVORITE_SUCCESS:
      return {
        ...state,
        [StateKeys.waterbody]: action.payload.data,
      };

    //
    // Recommendations
    //

    case ReduxActions.WATERBODY_RECOMMENDATIONS_FETCH_PENDING:
    case ReduxActions.RECOMMENDATIONS_FAMILIES_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.recommendationsLoadingState]: LoadingState.PENDING,
        [StateKeys.recommendationsTimestamp]: action.payload.timestamp,
      };

    case ReduxActions.WATERBODY_RECOMMENDATIONS_FETCH_SUCCESS:
    case ReduxActions.RECOMMENDATIONS_FAMILIES_FETCH_SUCCESS:
      if (action.payload.timestamp < state[StateKeys.recommendationsTimestamp]) {
        return state;
      }
      return {
        ...state,
        [StateKeys.recommendationsLoadingState]: LoadingState.DONE,
        [StateKeys.recommendations]: action.payload.data,
        [StateKeys.recommendationsCount]: action.payload.data.length,
      };

    case ReduxActions.USER_RECOMMENDATIONS_REMOVE_PRODUCT: {
      const recommendedFamilies = state[StateKeys.recommendations];
      const recommendations = recommendedFamilies.map((recommendedFamily) => {
        return {
          ...recommendedFamily,
          products: recommendedFamily.products.filter(
            (recommendation) => recommendation.sku !== action.payload.sku
          ),
        };
      });
      const recommendationsWithProducts = recommendations.filter(
        (recommendedFamily) => recommendedFamily.products.length > 0
      );
      return {
        ...state,
        [StateKeys.recommendations]: recommendationsWithProducts,
      };
    }

    //
    // Waterbody Activation
    //

    case ReduxActions.WATERBODY_RECOMMENDATIONS_COUNT_SET: {
      return {
        ...state,
        [StateKeys.recommendationsCount]: action.payload.count,
      };
    }

    case ReduxActions.WATERBODY_REQUEST_ACTIVATION_PENDING: {
      return {
        ...state,
        [StateKeys.activationLoadingState]: LoadingState.PENDING,
      };
    }

    case ReduxActions.WATERBODY_REQUEST_ACTIVATION_SUCCESS: {
      return {
        ...state,
        [StateKeys.activationLoadingState]: LoadingState.DONE,
      };
    }

    //
    // Ambassadors
    //

    case ReduxActions.WATERBODY_AMBASSADORS_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.ambassadors]: action.payload.data,
      };
    }

    //
    // Fishing Reports
    //
    case ReduxActions.WATERBODY_FISHING_REPORTS_FETCH_PENDING: {
      return {
        ...state,
        [StateKeys.fishingReportsLoadingState]: LoadingState.PENDING,
      };
    }

    case ReduxActions.WATERBODY_FISHING_REPORTS_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.fishingReports]: action.payload.data,
        [StateKeys.fishingReportsLoadingState]: LoadingState.DONE,
      };
    }

    // Tournaments

    case ReduxActions.WATERBODY_TOURNAMENTS_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.tournaments]: action.payload.data,
      };
    }

    //
    // Waterbody Styles
    //

    case ReduxActions.WATERBODY_WATERBODY_TECHNIQUES_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.waterbodyTechniques]: action.payload.data,
      };
    }

    //
    // Waterbody Event Notifications
    //

    case ReduxActions.WATERBODY_EVENT_NOTIFICATIONS_UPDATE_PENDING: {
      return {
        ...state,
        [StateKeys.eventNotificationLoadingState]: LoadingState.PENDING,
      };
    }

    case ReduxActions.WATERBODY_EVENT_NOTIFICATIONS_UPDATE_SUCCESS: {
      return {
        ...state,
        [StateKeys.eventNotificationLoadingState]: LoadingState.DONE,
        [StateKeys.waterbody]: action.payload.data,
      };
    }

    //
    // Reset
    //

    case ReduxActions.WATERBODY_RESET:
      return initialState;

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const WaterbodyActions = {
  WATERBODY_RESET: () => createAction(ReduxActions.WATERBODY_RESET),

  WATERBODY_FETCH_PENDING: () => createAction(ReduxActions.WATERBODY_FETCH_PENDING),
  WATERBODY_FETCH_SUCCESS: (response: OmniaResponse<WaterbodyDetail>) =>
    createAction(ReduxActions.WATERBODY_FETCH_SUCCESS, response),
  WATERBODY_FETCH_ERROR: (err: any) => createAction(ReduxActions.WATERBODY_FETCH_ERROR, err),

  WATERBODY_FROM_PIN_FETCH_PENDING: () =>
    createAction(ReduxActions.WATERBODY_FROM_PIN_FETCH_PENDING),
  WATERBODY_FROM_PIN_FETCH_SUCCESS: (response: OmniaResponse<WaterbodyDetail>) =>
    createAction(ReduxActions.WATERBODY_FROM_PIN_FETCH_SUCCESS, response),
  WATERBODY_FROM_PIN_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.WATERBODY_FROM_PIN_FETCH_ERROR, err),

  WATERBODY_RECOMMENDATIONS_FETCH_PENDING: (timestamp: number) =>
    createAction(ReduxActions.WATERBODY_RECOMMENDATIONS_FETCH_PENDING, { timestamp }),
  WATERBODY_RECOMMENDATIONS_FETCH_SUCCESS: (
    response: OmniaResponseTimestamped<RecommendedProductFamily[]>
  ) => createAction(ReduxActions.WATERBODY_RECOMMENDATIONS_FETCH_SUCCESS, response),
  WATERBODY_RECOMMENDATIONS_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.WATERBODY_RECOMMENDATIONS_FETCH_ERROR, err),

  WATERBODY_RECOMMENDATIONS_COUNT_SET: (count: number) =>
    createAction(ReduxActions.WATERBODY_RECOMMENDATIONS_COUNT_SET, { count }),

  WATERBODY_REQUEST_ACTIVATION_PENDING: () =>
    createAction(ReduxActions.WATERBODY_REQUEST_ACTIVATION_PENDING),
  WATERBODY_REQUEST_ACTIVATION_SUCCESS: (response: OmniaResponse<{}>) =>
    createAction(ReduxActions.WATERBODY_REQUEST_ACTIVATION_SUCCESS, response),
  WATERBODY_REQUEST_ACTIVATION_ERROR: (err: any) =>
    createAction(ReduxActions.WATERBODY_REQUEST_ACTIVATION_ERROR, err),

  WATERBODY_AMBASSADORS_FETCH_PENDING: () =>
    createAction(ReduxActions.WATERBODY_AMBASSADORS_FETCH_PENDING),
  WATERBODY_AMBASSADORS_FETCH_SUCCESS: (response: OmniaResponse<AmbassadorDetail[]>) =>
    createAction(ReduxActions.WATERBODY_AMBASSADORS_FETCH_SUCCESS, response),
  WATERBODY_AMBASSADORS_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.WATERBODY_AMBASSADORS_FETCH_ERROR, err),

  WATERBODY_FISHING_REPORTS_FETCH_PENDING: () =>
    createAction(ReduxActions.WATERBODY_FISHING_REPORTS_FETCH_PENDING),
  WATERBODY_FISHING_REPORTS_FETCH_SUCCESS: (response: OmniaResponse<FishingReport[]>) =>
    createAction(ReduxActions.WATERBODY_FISHING_REPORTS_FETCH_SUCCESS, response),
  WATERBODY_FISHING_REPORTS_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.WATERBODY_FISHING_REPORTS_FETCH_ERROR, err),

  WATERBODY_WATERBODY_TECHNIQUES_FETCH_PENDING: () =>
    createAction(ReduxActions.WATERBODY_WATERBODY_TECHNIQUES_FETCH_PENDING),
  WATERBODY_WATERBODY_TECHNIQUES_FETCH_SUCCESS: (response: OmniaResponse<WaterbodyTechnique[]>) =>
    createAction(ReduxActions.WATERBODY_WATERBODY_TECHNIQUES_FETCH_SUCCESS, response),
  WATERBODY_WATERBODY_TECHNIQUES_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.WATERBODY_WATERBODY_TECHNIQUES_FETCH_ERROR, err),

  WATERBODY_EVENT_NOTIFICATIONS_UPDATE_PENDING: () =>
    createAction(ReduxActions.WATERBODY_EVENT_NOTIFICATIONS_UPDATE_PENDING),
  WATERBODY_EVENT_NOTIFICATIONS_UPDATE_SUCCESS: (response: OmniaResponse<WaterbodyDetail>) =>
    createAction(ReduxActions.WATERBODY_EVENT_NOTIFICATIONS_UPDATE_SUCCESS, response),
  WATERBODY_EVENT_NOTIFICATIONS_UPDATE_ERROR: (err: any) =>
    createAction(ReduxActions.WATERBODY_EVENT_NOTIFICATIONS_UPDATE_ERROR, err),

  WATERBODY_FAVORITE_PENDING: () => createAction(ReduxActions.WATERBODY_FAVORITE_PENDING),
  WATERBODY_FAVORITE_SUCCESS: (response: OmniaResponse<WaterbodyDetail>) =>
    createAction(ReduxActions.WATERBODY_FAVORITE_SUCCESS, response),
  WATERBODY_FAVORITE_ERROR: (err: any) => createAction(ReduxActions.WATERBODY_FAVORITE_ERROR, err),

  WATERBODY_UNFAVORITE_PENDING: () => createAction(ReduxActions.WATERBODY_UNFAVORITE_PENDING),
  WATERBODY_UNFAVORITE_SUCCESS: (response: OmniaResponse<WaterbodyDetail>) =>
    createAction(ReduxActions.WATERBODY_UNFAVORITE_SUCCESS, response),
  WATERBODY_UNFAVORITE_ERROR: (err: any) =>
    createAction(ReduxActions.WATERBODY_UNFAVORITE_ERROR, err),

  WATERBODY_TOURNAMENTS_FETCH_PENDING: () =>
    createAction(ReduxActions.WATERBODY_TOURNAMENTS_FETCH_PENDING),
  WATERBODY_TOURNAMENTS_FETCH_SUCCESS: (response: OmniaResponse<Tournament[]>) =>
    createAction(ReduxActions.WATERBODY_TOURNAMENTS_FETCH_SUCCESS, response),
  WATERBODY_TOURNAMENTS_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.WATERBODY_TOURNAMENTS_FETCH_ERROR, err),
};
export type WaterbodyActions = ActionsUnion<typeof WaterbodyActions>;

export function fetchWaterbody(slug: string): RequestThunk {
  return (dispatch) => {
    dispatch(WaterbodyActions.WATERBODY_FETCH_PENDING());

    return apiV1
      .waterbodyFetch(slug)
      .then((response) => {
        return dispatch(WaterbodyActions.WATERBODY_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchWaterbody: ${slug}`, error);
        return dispatch(WaterbodyActions.WATERBODY_FETCH_ERROR(error));
      });
  };
}

export function fetchWaterbodyFromPin(slug: string): RequestThunk {
  return (dispatch) => {
    dispatch(WaterbodyActions.WATERBODY_FROM_PIN_FETCH_PENDING());

    return apiV1
      .waterbodyFetch(slug)
      .then((response) => {
        return dispatch(WaterbodyActions.WATERBODY_FROM_PIN_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchWaterbodyFromPin: ${slug}`, error);
        return dispatch(WaterbodyActions.WATERBODY_FROM_PIN_FETCH_ERROR(error));
      });
  };
}

export interface FetchWaterbodyRecommendationsParams {
  clarity?: number;
  species?: string | string[];
  styles?: string | string[];
  seasons?: string | string[];
}

export function fetchWaterbodyRecommendations(
  slug: string,
  params: FetchWaterbodyRecommendationsParams = {}
): RequestThunk {
  return (dispatch) => {
    const now = getNow();
    dispatch(WaterbodyActions.WATERBODY_RECOMMENDATIONS_FETCH_PENDING(now));

    return apiV1
      .waterbodyRecommendationsFetch(slug, params)
      .then((response) => {
        return dispatch(
          WaterbodyActions.WATERBODY_RECOMMENDATIONS_FETCH_SUCCESS({
            ...response.data,
            timestamp: now,
          })
        );
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchWaterbodyRecommendations: ${slug}`, error);
        return dispatch(WaterbodyActions.WATERBODY_RECOMMENDATIONS_FETCH_ERROR(error));
      });
  };
}

export function requestWaterbodyActivation(
  slug: string,
  email: string,
  species: string[],
  clarity: number,
  description: string
): RequestThunk {
  return (dispatch) => {
    dispatch(WaterbodyActions.WATERBODY_REQUEST_ACTIVATION_PENDING());

    return apiV1
      .waterbodyActivate(slug, {
        email,
        species,
        clarity,
        description,
      })
      .then((response) => {
        return dispatch(WaterbodyActions.WATERBODY_REQUEST_ACTIVATION_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(
          `ERROR: requestWaterbodyActivation: ${slug}, ${email}, ${species}, ${clarity}, ${description}`,
          error
        );
        return dispatch(WaterbodyActions.WATERBODY_REQUEST_ACTIVATION_ERROR(error));
      });
  };
}

export function fetchWaterbodyAmbassadors(slug: string): RequestThunk {
  return (dispatch) => {
    dispatch(WaterbodyActions.WATERBODY_AMBASSADORS_FETCH_PENDING());

    return apiV1
      .waterbodyAmbassadorsFetch(slug)
      .then((response) => {
        return dispatch(WaterbodyActions.WATERBODY_AMBASSADORS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchWaterbodyAmbassadors: ${slug}`, error);
        return dispatch(WaterbodyActions.WATERBODY_AMBASSADORS_FETCH_ERROR(error));
      });
  };
}

export function fetchWaterbodyFishingReports(slug: string): RequestThunk {
  return (dispatch) => {
    dispatch(WaterbodyActions.WATERBODY_FISHING_REPORTS_FETCH_PENDING());

    return apiV1
      .waterbodyFishingReportsFetch(slug)
      .then((response) => {
        return dispatch(WaterbodyActions.WATERBODY_FISHING_REPORTS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchWaterbodyFishingReports: ${slug}`, error);
        return dispatch(WaterbodyActions.WATERBODY_FISHING_REPORTS_FETCH_ERROR(error));
      });
  };
}

export function fetchWaterbodyTechniques(
  slug: string,
  params: {
    species: string;
    season_group: string;
  }
): RequestThunk {
  return (dispatch) => {
    dispatch(WaterbodyActions.WATERBODY_WATERBODY_TECHNIQUES_FETCH_PENDING());

    return apiV1
      .waterbodyTechniquesFetch(slug, params)
      .then((response) => {
        return dispatch(
          WaterbodyActions.WATERBODY_WATERBODY_TECHNIQUES_FETCH_SUCCESS(response.data)
        );
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchWaterbodyTechniques: ${slug}`, error);
        return dispatch(WaterbodyActions.WATERBODY_WATERBODY_TECHNIQUES_FETCH_ERROR(error));
      });
  };
}

export function favoriteWaterbody(
  slug: string,
  eventNotifications: WaterbodyEventNotifications,
  email: string
): RequestThunk {
  return (dispatch) => {
    dispatch(WaterbodyActions.WATERBODY_FAVORITE_PENDING());

    return apiV1
      .waterbodyFavorite(slug, eventNotifications, email)
      .then((response) => {
        return dispatch(WaterbodyActions.WATERBODY_FAVORITE_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: favoriteWaterbody: ${slug}`, error);
        return dispatch(WaterbodyActions.WATERBODY_FAVORITE_ERROR(error));
      });
  };
}

export function unfavoriteWaterbody(slug: string): RequestThunk {
  return (dispatch) => {
    dispatch(WaterbodyActions.WATERBODY_UNFAVORITE_PENDING());

    return apiV1
      .waterbodyUnfavorite(slug)
      .then((response) => {
        return dispatch(WaterbodyActions.WATERBODY_UNFAVORITE_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: unfavoriteWaterbody: ${slug}`, error);
        return dispatch(WaterbodyActions.WATERBODY_UNFAVORITE_ERROR(error));
      });
  };
}

export function updateWaterbodyNotifications(
  slug: string,
  eventNotifications: WaterbodyEventNotifications,
  email: string
): RequestThunk {
  return (dispatch) => {
    dispatch(WaterbodyActions.WATERBODY_EVENT_NOTIFICATIONS_UPDATE_PENDING());

    return apiV1
      .waterbodyUpdateNotifications(slug, eventNotifications, email)
      .then((response) => {
        return dispatch(
          WaterbodyActions.WATERBODY_EVENT_NOTIFICATIONS_UPDATE_SUCCESS(response.data)
        );
      })
      .catch((error) => {
        errorHandler(`ERROR: updateWaterbodyNotifications: ${slug}`, error);
        return dispatch(WaterbodyActions.WATERBODY_EVENT_NOTIFICATIONS_UPDATE_ERROR(error));
      });
  };
}

export function fetchWaterbodyTournaments(slug: string): RequestThunk {
  return (dispatch) => {
    dispatch(WaterbodyActions.WATERBODY_TOURNAMENTS_FETCH_PENDING());

    return apiV1
      .waterbodyTournamentsFetch(slug)
      .then((response) => {
        return dispatch(WaterbodyActions.WATERBODY_TOURNAMENTS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchWaterbodyTournaments: ${slug}`, error);
        return dispatch(WaterbodyActions.WATERBODY_TOURNAMENTS_FETCH_ERROR(error));
      });
  };
}
