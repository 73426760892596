import { CartPromoCartContains, LineItem } from '@omniafishing/core';
import { Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useCart } from '../../hooks/use_cart';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { taxonomyByProductType } from '../../lib/variant';
import { WebAnalytics } from '../../lib/web_analytics';
import { ProductFamilyQuickModalActions } from '../../redux/product_family_quick_modal';
import styles from './cart_price_promo.less';

interface PromoProps {
  promo: CartPromoCartContains;
}

export const cartQualifiesForPromo = (promo: CartPromoCartContains, lineItems: LineItem[]) => {
  const { cart_contains_array, quantity } = promo;

  return cart_contains_array.some((cart_contains) => {
    const { cart_contains_type, cart_contains_item } = cart_contains;
    switch (cart_contains_type) {
      case 'Subcategory': {
        const quantityInSubcategory = lineItems.reduce((count, lineItem) => {
          if (
            taxonomyByProductType(lineItem.variant.product.productType).subcategory ===
            cart_contains_item.name
          ) {
            return count + lineItem.quantity;
          }
          return count;
        }, 0);
        return quantityInSubcategory >= quantity;
      }
      case 'Category': {
        const quantityInCategory = lineItems.reduce((count, lineItem) => {
          if (
            taxonomyByProductType(lineItem.variant.product.productType).category ===
            cart_contains_item.name
          ) {
            return count + lineItem.quantity;
          }
          return count;
        }, 0);
        return quantityInCategory >= quantity;
      }
      case 'ProductFamily': {
        const quantityOfProductFamily = lineItems.reduce((count, lineItem) => {
          if (lineItem.variant.product.handle === cart_contains_item.handle) {
            return count + lineItem.quantity;
          }
          return count;
        }, 0);
        return quantityOfProductFamily >= quantity;
      }
      default:
        return false;
    }
  });
};

export const CartContainsPromo = (props: PromoProps) => {
  const { product_family, discount_percent, message } = props.promo;

  const dispatch = useDispatch();
  const { lineItems, subtotalPrice } = useCart();
  const cartQualifies = cartQualifiesForPromo(props.promo, lineItems);

  const open = () => {
    dispatch(
      ProductFamilyQuickModalActions.PRODUCT_FAMILY_QUICK_MODAL_OPEN({
        recommendedFamily: {
          product_family,
          products: cartQualifies
            ? product_family.products.map((p) => {
                return {
                  ...p,
                  compare_at_price: p.price,
                  price: (Number(p.price) * (1 - discount_percent / 100)).toString(),
                  on_sale: true,
                };
              })
            : product_family.products,
          contains_all_colors: true,
        },
        context: 'shopping',
        onCartAdd: () => {
          WebAnalytics.cartDealAdded({
            deal_message: message,
            cart_total_amount: parseFloat(subtotalPrice),
            cart_total_items: lineItems.length,
            cart_total_quantity: lineItems.reduce((acc, li) => acc + li.quantity, 0),
          });
        },
      })
    );
    WebAnalytics.productClick({
      productOrProductFamily: product_family,
      show_modal: true,
      position: null,
    });
    WebAnalytics.cartClick('[cart].(deals_product_click)', {
      cart_total_amount: parseFloat(subtotalPrice),
      cart_total_items: lineItems.length,
      cart_total_quantity: lineItems.reduce((acc, li) => acc + li.quantity, 0),
    });
  };

  return (
    <div className={styles.promo} onClick={cartQualifies ? open : null}>
      <div className={styles.img}>
        <img src={setShopifyImgWidth(product_family.img_url, 200)} alt="" width="100" />
      </div>
      <div>
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: message }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
        {cartQualifies && (
          <Button className={styles.currentPromo}>
            Now {discount_percent < 100 ? `${discount_percent} % Off` : 'FREE'}
          </Button>
        )}
      </div>
    </div>
  );
};
