import { AlgoliaAmbassador, AmbassadorMini } from '@omniafishing/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { OmniaUrls } from '../../lib/urls';
import { UserProfileImgRinged } from '../user_profile_img_ringed/user_profile_img_ringed';
import styles from './search_ambassador.less';

interface SearchAmbassadorProps {
  ambassador: AlgoliaAmbassador | AmbassadorMini;
  onClick: (target_url: string) => void;
}

function isTypeAlgoliaAmbassador(
  value: AlgoliaAmbassador | AmbassadorMini
): value is AlgoliaAmbassador {
  return value.hasOwnProperty('_highlightResult');
}

export const SearchAmbassador = (props: SearchAmbassadorProps) => {
  const { ambassador, onClick } = props;

  const { role, image, slug } = ambassador;
  const fullName = isTypeAlgoliaAmbassador(ambassador)
    ? ambassador._highlightResult.full_name?.value
    : ambassador.full_name;

  return (
    <Link
      to={OmniaUrls.ambassador(slug)}
      className={styles.link}
      onClick={() => onClick(OmniaUrls.ambassador(slug))}
    >
      <div className={styles.ambassador}>
        <div className={styles.img}>
          <UserProfileImgRinged
            role={role}
            image={image}
            imageSize={100}
            size={80}
            borderSize={4}
            style={{ flex: '0 0 auto' }}
          />
        </div>
        <span className={styles.name} dangerouslySetInnerHTML={{ __html: fullName }} />
      </div>
    </Link>
  );
};
