import { StarFilled } from '@ant-design/icons';
import classNames from 'classnames';
import * as React from 'react';
import SvgLakes from '../svg/lakes';
import styles from './pins.less';
import { formatFishingReportsCount, PinProps } from './pin_low_activity';

export const PinHighActivity = (props: PinProps) => {
  const { favorite, featured, selected, onClick, className, fishingReportCount = 0 } = props;
  const style: React.CSSProperties = {};

  return (
    <div
      className={classNames(
        styles.pinWrapper,
        styles.pinWrapper__lg,
        {
          [styles.pinWrapper__selected]: selected,
        },
        className
      )}
      onClick={onClick}
      style={style}
    >
      <div
        className={classNames(styles.pin, styles.pin__lg, {
          [styles.pin__featured]: featured,
          [styles.pin__favorite]: favorite,
        })}
        style={style}
      >
        {favorite ? (
          <StarFilled className={styles.svgStar} />
        ) : (
          <SvgLakes className={styles.svgLake} />
        )}
        {fishingReportCount > 0 && (
          <span className={styles.pinReportCount}>
            {formatFishingReportsCount(fishingReportCount)}
          </span>
        )}
      </div>
    </div>
  );
};

PinHighActivity.width = 48;
PinHighActivity.height = 48;
