import { FishingReport, TechniqueFull } from '@omniafishing/core';
import { Button, Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  fishingReportContainsSeasonGroup,
  fishingReportContainsSku,
  fishingReportContainsSpecieName,
  fishingReportContainsTechniqueName,
} from '../../../lib/fishing_reports';
import { getSeasonGroups, getTechniques } from '../../../redux/reference_data';
import styles from './all_filters_drawer.less';

interface TechniqueCheckboxesProps {
  drawerTechniques: CheckboxValueType[];
  fishingReports: FishingReport[];
  onChange: (techniques: CheckboxValueType[]) => void;
  drawerProductSku: string;
  drawerSeasonGroupName: string;
  drawerSpeciesName: string;
}
export const TechniqueCheckboxes = (props: TechniqueCheckboxesProps) => {
  const allTechniques = useSelector(getTechniques);
  const seasonGroups = useSelector(getSeasonGroups);
  const [showMore, setShowMore] = useState(false);

  const {
    drawerTechniques,
    fishingReports,
    onChange,
    drawerProductSku,
    drawerSeasonGroupName,
    drawerSpeciesName,
  } = props;

  const techniquesPresentInFishingReports = allTechniques.filter((technique) => {
    const reportCount = fishingReports.filter((report) => {
      return fishingReportContainsTechniqueName(report, technique.name);
    }).length;
    if (reportCount > 0) {
      return technique;
    }
  });

  const SeasonGroupOfSeasonGroupName = seasonGroups.find(
    (season) => season.name === drawerSeasonGroupName
  );

  const countsOfTechniqueMatchesFilteredByProductSeasonSpecies: number[] =
    techniquesPresentInFishingReports.map((technique) => {
      if (drawerProductSku || drawerSeasonGroupName || drawerSpeciesName) {
        return fishingReports.filter((report) => {
          const reportContainsTechnique = fishingReportContainsTechniqueName(
            report,
            technique.name
          );
          const reportContainsProductSku =
            drawerProductSku && fishingReportContainsSku(report, drawerProductSku);
          const reportContainsSeasonGroup =
            drawerSeasonGroupName &&
            fishingReportContainsSeasonGroup(report, SeasonGroupOfSeasonGroupName);
          const reportContainsSpeciesName = fishingReportContainsSpecieName(
            report,
            drawerSpeciesName
          );

          if (drawerSeasonGroupName && !!drawerProductSku && !!drawerSpeciesName) {
            return (
              reportContainsTechnique &&
              reportContainsSeasonGroup &&
              reportContainsSpeciesName &&
              reportContainsProductSku
            );
          } else if (!!drawerProductSku && !!drawerSeasonGroupName) {
            return reportContainsTechnique && reportContainsProductSku && reportContainsSeasonGroup;
          } else if (!!drawerProductSku && !!drawerSpeciesName) {
            return reportContainsTechnique && reportContainsProductSku && reportContainsSpeciesName;
          } else if (!!drawerSeasonGroupName && !!drawerSpeciesName) {
            return (
              reportContainsTechnique && reportContainsSeasonGroup && reportContainsSpeciesName
            );
          } else if (!!drawerProductSku) {
            return reportContainsTechnique && reportContainsProductSku;
          } else if (!!drawerSeasonGroupName) {
            return reportContainsTechnique && reportContainsSeasonGroup;
          } else if (!!drawerSpeciesName) {
            return reportContainsTechnique && reportContainsSpeciesName;
          }
        }).length;
      }

      return fishingReports.filter((report) => {
        return fishingReportContainsTechniqueName(report, technique.name);
      }).length;
    });

  const techniquesList = (techniques: TechniqueFull[]) => {
    return techniques.map((technique, i) => {
      return (
        <Checkbox
          value={technique.name}
          className={styles.checkboxLabel}
          key={technique.name}
          disabled={countsOfTechniqueMatchesFilteredByProductSeasonSpecies[i] === 0}
        >
          <span className={styles.radioName}>{technique.display_name}</span>
          <span className={styles.checkboxLabelReportCount}>
            {countsOfTechniqueMatchesFilteredByProductSeasonSpecies[i]}
          </span>
        </Checkbox>
      );
    });
  };

  return (
    <div className={styles.checkboxesContainer}>
      <Checkbox.Group
        onChange={(techniqueNames) => {
          onChange(techniqueNames);
        }}
        value={drawerTechniques}
        className={styles.checkboxGroup}
      >
        {showMore
          ? techniquesList(techniquesPresentInFishingReports)
          : techniquesList(techniquesPresentInFishingReports.slice(0, 9))}
      </Checkbox.Group>
      {techniquesPresentInFishingReports.length > 9 ? (
        <Button
          type="link"
          size="small"
          className={styles.showMoreLinkButton}
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? 'Show Less' : 'Show All'}
        </Button>
      ) : null}
    </div>
  );
};
