import { PlusOutlined } from '@ant-design/icons';
import { WaterbodyDetail, WaterTemperatureData } from '@omniafishing/core';
import { Card } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useOnClickOutside } from '../../../../hooks/use_on_click_outside';
import { useQueryString } from '../../../../hooks/use_query_string';
import { apiV1 } from '../../../../lib/api';
import { OmniaQueryParams } from '../../../../lib/query_string';
import { buildGetStartedUrl, OmniaUrls } from '../../../../lib/urls';
import { WebAnalytics } from '../../../../lib/web_analytics';
import { FishingReportModalActions } from '../../../../redux/fishing_report_modal';
import { getSeasonGroups, getSpecies } from '../../../../redux/reference_data';
import { getUser } from '../../../../redux/user';
import { WaterbodyPaths } from '../../../../routes';
import { OmniaButton } from '../../../omnia_button/omnia_button';
import { OmniaLinkButton } from '../../../omnia_button/omnia_link_button';
import { OmniaLink } from '../../../omnia_link/omnia_link';
import SvgFire from '../../../svg/fire';
import { WaterbodyFollowButtonLoggedIn } from '../../../waterbody_follow_button_logged_in/waterbody_follow_button_logged_in';
import { SvgStarRounded } from '../../star_rounded';
import styles from './lake_details_modal.less';
import { MapPageQueryParams } from '../../../../routes/map_page/lib/map_page_types';

interface MapLakeDetailsProps {
  onClose: () => void;
  waterbody: WaterbodyDetail;
  replaceFileReportWithFollowButton?: boolean;
  hideFileReportButton?: boolean;
}

export const LakeDetailsModal = React.memo((props: MapLakeDetailsProps) => {
  const { waterbody, onClose, replaceFileReportWithFollowButton, hideFileReportButton } = props;

  const { getCurrentQuery, stringify } = useQueryString();
  const currentQuery = getCurrentQuery<
    MapPageQueryParams & {
      onboarding: string;
      waterbody: string;
    }
  >();
  const containerRef = useRef<HTMLDivElement>(null);
  const allSpecies = useSelector(getSpecies);
  const allSeasonGroups = useSelector(getSeasonGroups);
  const user = useSelector(getUser);
  const [waterTempData, setWaterTempData] = useState<WaterTemperatureData>();

  const getStartedOnboarding = currentQuery.onboarding === '1';
  const querySpecies = allSpecies.find((s) => s.name === currentQuery.species);
  const querySeasonGroup = allSeasonGroups.find((sg) => sg.name === currentQuery.season_group);

  const {
    locales,
    primary_name,
    favorites_count,
    fishing_reports_count,
    hotbaits_count,
    ambassadors_count,
    url_slug,
  } = waterbody;

  const hasFishingReport = fishing_reports_count > 0;
  const hasUserFavs = favorites_count > 0;
  const hasAmbassadors = ambassadors_count > 0;
  const hasHotbaits = hotbaits_count > 0;

  const dispatch = useDispatch();

  useEffect(() => {
    apiV1.waterbodyWaterTemperatureFetch(url_slug).then((res) => {
      setWaterTempData(res.data.data);
    });
  }, []);

  useOnClickOutside(containerRef, onClose, 'mousedown', 'touchstart');

  return (
    <div ref={containerRef}>
      <Card className={styles.lakeDetails__card} bodyStyle={{ padding: '0' }}>
        <div className={styles.details}>
          <h1 id={primary_name} className={styles.h1}>
            {primary_name}
            {locales.length > 0 && (
              <>
                {' '}
                (
                {locales
                  .map<React.ReactNode>((local) => (
                    <Link
                      to={OmniaUrls.state(local.state)}
                      key={local.state.abbr}
                      onClick={() => {
                        WebAnalytics.mapClick('[map_modal].(state_link)', waterbody.url_slug);
                      }}
                    >
                      {local.state.abbr}
                    </Link>
                  ))
                  // I do not understand this, but it works.
                  .reduce((prev, curr) => [prev, ', ', curr])}
                )
              </>
            )}
          </h1>

          {waterTempData && (
            <p className={styles.waterTemp}>
              Current Water Temp: <strong>{waterTempData.water_temp} °F</strong>{' '}
              <OmniaLink
                to={OmniaUrls.waterbody(waterbody, WaterbodyPaths.CURRENT_CONDITIONS)}
                underline
                onClick={() => {
                  WebAnalytics.waterTempDataTracked({
                    action: 'modal',
                    premium: !!user?.premium,
                  });
                }}
              >
                View Data
              </OmniaLink>
            </p>
          )}

          <div className={styles.quickDetails}>
            {hasFishingReport && (
              <p className={styles.quickDetail}>
                <span className={styles.quickDetailIcon}>🎣</span>
                {fishing_reports_count} Fishing Report{fishing_reports_count > 1 && 's'}
              </p>
            )}
            {hasHotbaits && (
              <p className={styles.quickDetail}>
                <span className={styles.quickDetailIcon}>
                  <SvgFire className={styles.fire} />
                </span>
                {hotbaits_count} Hotbait{hotbaits_count > 1 && 's'}
              </p>
            )}
            {hasAmbassadors && (
              <p className={styles.quickDetail}>
                <span className={styles.quickDetailIcon}>🏆</span>
                {ambassadors_count} Ambassador{ambassadors_count > 1 && 's'}
              </p>
            )}
            {hasUserFavs && (
              <p className={styles.quickDetail}>
                <span
                  className={classNames(styles.quickDetailIcon, styles.quickDetailIcon__favorite)}
                >
                  <SvgStarRounded />
                </span>
                {favorites_count} User Favorite{favorites_count > 1 && 's'}
              </p>
            )}
          </div>

          {getStartedOnboarding ? (
            <OmniaLinkButton
              to={buildGetStartedUrl(querySpecies, waterbody.url_slug)}
              kind="secondary"
              size="md"
              fontSize={14}
              block
            >
              Choose lake
            </OmniaLinkButton>
          ) : (
            <div className={styles.buttonGroup}>
              {replaceFileReportWithFollowButton ? (
                <WaterbodyFollowButtonLoggedIn
                  waterbody={waterbody}
                  blockButton
                  noPopup
                  className={styles.followButton}
                  onClick={onClose}
                />
              ) : (
                <>
                  {!hideFileReportButton && (
                    <OmniaButton
                      size="md"
                      kind="tertiary"
                      fontSize={14}
                      onPress={() => {
                        dispatch(FishingReportModalActions.FISHING_REPORT_MODAL_OPEN(waterbody));
                      }}
                      block
                    >
                      <PlusOutlined className={styles.plusIcon} /> File a Fishing Report
                    </OmniaButton>
                  )}
                </>
              )}
              <OmniaLinkButton
                to={
                  OmniaUrls.waterbody(waterbody) +
                  `?${stringify({
                    [OmniaQueryParams.species]: querySpecies?.name,
                    [OmniaQueryParams.season_group]: querySeasonGroup?.name,
                  })}`
                }
                kind={replaceFileReportWithFollowButton ? 'tertiary' : 'primary'}
                size="md"
                fontSize={14}
                block
                onPress={() => {
                  WebAnalytics.mapClick('[map_modal].(lake_details_button)', waterbody.url_slug);
                }}
                className={classNames({
                  [styles.switchButtonOrder]: replaceFileReportWithFollowButton,
                })}
              >
                See Lake Details
              </OmniaLinkButton>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
});
