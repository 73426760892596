import {
  FishingReport,
  OmniaResponse,
  Organization,
  OrganizationDetail,
  OrganizationsFetchParams,
  Season,
  Species,
  Waterbody,
} from '@omniafishing/core';
import { createSelector } from 'reselect';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import { ActionsUnion, createAction } from './actions_helper';
import { RouterActions } from './router';

export const reducerName = 'organizations';

export enum StateKeys {
  organizations = 'organizations',
  organization = 'organization',
  loadingState = 'loadingState',
  fishingReports = 'fishingReports',
  fishingReportLoadingState = 'fishingReportLoadingState',
  facets = 'facets',
}

export const initialState = {
  [StateKeys.loadingState]: LoadingState.NOT_STARTED,
  [StateKeys.organizations]: [] as Organization[],
  [StateKeys.organization]: null as OrganizationDetail,
  [StateKeys.fishingReports]: [] as FishingReport[],
  [StateKeys.fishingReportLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.facets]: {
    species: [] as Species[],
    seasons: [] as Season[],
    waterbodies: [] as Waterbody[],
  },
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.loadingState];
export const getOrganizations = (state: ApplicationState) =>
  state[reducerName][StateKeys.organizations];
export const getOrganizationsPublic = createSelector([getOrganizations], (orgs) => {
  return orgs.filter((o) => !o.restrict_membership);
});
export const getOrganization = (state: ApplicationState) =>
  state[reducerName][StateKeys.organization];
export const getFishingReports = (state: ApplicationState) =>
  state[reducerName][StateKeys.fishingReports];
export const getFishingReportLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.fishingReportLoadingState];
export const getFacets = (state: ApplicationState) => state[reducerName][StateKeys.facets];

// ========================================================================== //
// Reducer
// ========================================================================== //

export default function OrganizationsReducer(
  state = initialState,
  action: OrganizationsActions | RouterActions
) {
  switch (action.type) {
    case ReduxActions.ORGANIZATIONS_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.PENDING,
        [StateKeys.organizations]: [],
      };

    case ReduxActions.ORGANIZATIONS_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.DONE,
        [StateKeys.organizations]: action.payload.data,
      };

    case ReduxActions.ORGANIZATION_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.organization]: null,
      };

    case ReduxActions.ORGANIZATION_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.organization]: action.payload.data,
      };

    case ReduxActions.ORGANIZATION_FETCH_FISHING_REPORTS_PENDING:
      return {
        ...state,
        [StateKeys.fishingReportLoadingState]: LoadingState.PENDING,
        [StateKeys.fishingReports]: [],
        [StateKeys.facets]: initialState[StateKeys.facets],
      };

    case ReduxActions.ORGANIZATION_FETCH_FISHING_REPORTS_SUCCESS:
      return {
        ...state,
        [StateKeys.fishingReportLoadingState]: LoadingState.DONE,
        [StateKeys.fishingReports]: action.payload.data.fishing_reports,
        [StateKeys.facets]: action.payload.data.facets,
      };

    case ReduxActions.ROUTER_LOCATION_CHANGE:
      return initialState;

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const OrganizationsActions = {
  ORGANIZATIONS_FETCH_PENDING: () => createAction(ReduxActions.ORGANIZATIONS_FETCH_PENDING),
  ORGANIZATIONS_FETCH_SUCCESS: (response: OmniaResponse<Organization[]>) =>
    createAction(ReduxActions.ORGANIZATIONS_FETCH_SUCCESS, response),
  ORGANIZATIONS_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.ORGANIZATIONS_FETCH_ERROR, err),

  ORGANIZATION_FETCH_PENDING: () => createAction(ReduxActions.ORGANIZATION_FETCH_PENDING),
  ORGANIZATION_FETCH_SUCCESS: (response: OmniaResponse<OrganizationDetail>) =>
    createAction(ReduxActions.ORGANIZATION_FETCH_SUCCESS, response),
  ORGANIZATION_FETCH_ERROR: (err: any) => createAction(ReduxActions.ORGANIZATION_FETCH_ERROR, err),

  ORGANIZATION_JOIN_PENDING: () => createAction(ReduxActions.ORGANIZATION_JOIN_PENDING),
  ORGANIZATION_JOIN_SUCCESS: (response: OmniaResponse<Organization[]>) =>
    createAction(ReduxActions.ORGANIZATION_JOIN_SUCCESS, response),
  ORGANIZATION_JOIN_ERROR: (err: any) => createAction(ReduxActions.ORGANIZATION_JOIN_ERROR, err),

  ORGANIZATION_LEAVE_PENDING: () => createAction(ReduxActions.ORGANIZATION_LEAVE_PENDING),
  ORGANIZATION_LEAVE_SUCCESS: (response: OmniaResponse<Organization[]>) =>
    createAction(ReduxActions.ORGANIZATION_LEAVE_SUCCESS, response),
  ORGANIZATION_LEAVE_ERROR: (err: any) => createAction(ReduxActions.ORGANIZATION_LEAVE_ERROR, err),

  ORGANIZATION_FETCH_FISHING_REPORTS_PENDING: () =>
    createAction(ReduxActions.ORGANIZATION_FETCH_FISHING_REPORTS_PENDING),
  ORGANIZATION_FETCH_FISHING_REPORTS_SUCCESS: (
    response: OmniaResponse<{
      fishing_reports: FishingReport[];
      facets: {
        seasons: Season[];
        species: Species[];
        waterbodies: Waterbody[];
      };
    }>
  ) => createAction(ReduxActions.ORGANIZATION_FETCH_FISHING_REPORTS_SUCCESS, response),
  ORGANIZATION_FETCH_FISHING_REPORTS_ERROR: (err: any) =>
    createAction(ReduxActions.ORGANIZATION_FETCH_FISHING_REPORTS_ERROR, err),
};
export type OrganizationsActions = ActionsUnion<typeof OrganizationsActions>;

export function fetchOrganizations(params: OrganizationsFetchParams = {}): RequestThunk {
  return (dispatch) => {
    dispatch(OrganizationsActions.ORGANIZATIONS_FETCH_PENDING());

    return apiV1
      .organizationsFetch(params)
      .then((response) => {
        return dispatch(OrganizationsActions.ORGANIZATIONS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler('ERROR: fetchOrganizations', error);
        return dispatch(OrganizationsActions.ORGANIZATIONS_FETCH_ERROR(error));
      });
  };
}

export function fetchOrganization(slug: string): RequestThunk {
  return (dispatch) => {
    dispatch(OrganizationsActions.ORGANIZATION_FETCH_PENDING());

    return apiV1
      .organizationFetch(slug)
      .then((response) => {
        return dispatch(OrganizationsActions.ORGANIZATION_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchOrganization ${slug}`, error);
        return dispatch(OrganizationsActions.ORGANIZATION_FETCH_ERROR(error));
      });
  };
}

export function joinOrganization(slug: string): RequestThunk {
  return (dispatch) => {
    dispatch(OrganizationsActions.ORGANIZATION_JOIN_PENDING());

    return apiV1
      .organizationJoin(slug)
      .then((response) => {
        return dispatch(OrganizationsActions.ORGANIZATION_JOIN_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler('ERROR: joinOrganization', error);
        return dispatch(OrganizationsActions.ORGANIZATION_JOIN_ERROR(error));
      });
  };
}

export function joinOrganizationByRegistrationCode(registration_code: string): RequestThunk {
  return (dispatch) => {
    dispatch(OrganizationsActions.ORGANIZATION_JOIN_PENDING());

    return apiV1
      .organizationJoinByRegistrationCode(registration_code)
      .then((response) => {
        return dispatch(OrganizationsActions.ORGANIZATION_JOIN_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler('ERROR: joinOrganizationByRegistrationCode', error);
        return dispatch(OrganizationsActions.ORGANIZATION_JOIN_ERROR(error));
      });
  };
}

export function leaveOrganization(slug: string): RequestThunk {
  return (dispatch) => {
    dispatch(OrganizationsActions.ORGANIZATION_LEAVE_PENDING());

    return apiV1
      .organizationLeave(slug)
      .then((response) => {
        return dispatch(OrganizationsActions.ORGANIZATION_LEAVE_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler('ERROR: leaveOrganization', error);
        return dispatch(OrganizationsActions.ORGANIZATION_LEAVE_ERROR(error));
      });
  };
}

export function fetchOrganizationFishingReports(slug: string): RequestThunk {
  return (dispatch) => {
    dispatch(OrganizationsActions.ORGANIZATION_FETCH_FISHING_REPORTS_PENDING());

    return apiV1
      .organizationFishingReportsFetch(slug)
      .then((response) => {
        return dispatch(
          OrganizationsActions.ORGANIZATION_FETCH_FISHING_REPORTS_SUCCESS(response.data)
        );
      })
      .catch((error) => {
        errorHandler('ERROR: fetchOrganizations', error);
        return dispatch(OrganizationsActions.ORGANIZATION_FETCH_FISHING_REPORTS_ERROR(error));
      });
  };
}
