import { Waterbody } from '@omniafishing/core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUserWaterbodies } from '../redux/user';

export const useUserWaterbodies = () => {
  const userWaterbodies = useSelector(getUserWaterbodies);
  const userWaterbodyIds = useMemo(
    () => userWaterbodies.map((waterbody) => waterbody.id),
    [userWaterbodies]
  );

  return {
    userWaterbodies,
    isWaterbodyInUserWaterbodies: (waterbody: Waterbody | null) => {
      if (!waterbody) {
        return false;
      }
      return userWaterbodyIds.includes(waterbody.id);
    },
  };
};
