import {
  AmbassadorDetail,
  AmbassadorDetailExcludes,
  Brand,
  FishingReportWithWaterbodyDetail,
  ListTypes,
  OmniaResponse,
  Order,
  Organization,
  Product,
  UserPrivate,
  WaterbodyDetail,
} from '@omniafishing/core';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { RequestThunk } from '../../types/generic';
import { LoadingState } from '../constants/loading_state';
import { ReduxActions } from '../constants/redux_actions';
import { ApplicationState } from '../helpers/app_state';
import { apiV1 } from '../lib/api';
import { errorHandler } from '../lib/error_handler';
import { ActionsUnion, createAction } from './actions_helper';
import { AuthActions } from './auth';
import { OrganizationsActions } from './organizations';
import { UserProfileActions } from './user_profile';
import { WaterbodyActions } from './waterbody';

export const reducerName = 'user';

export enum StateKeys {
  brands = 'brands',
  experimentUuid = 'experimentUuid',
  experimentGroupReady = 'experimentGroupReady',
  favorites = 'favorites',
  favoriteAmbassadors = 'favoriteAmbassadors',
  favoriteAmbassadorsLoadingState = 'favoriteAmbassadorsLoadingState',
  fishingReports = 'fishingReports',
  fishingReportsLoadingState = 'fishingReportsLoadingState',
  loadingState = 'loadingState',
  orderProducts = 'orderProducts',
  orders = 'orders',
  ordersLoadingState = 'ordersLoadingState',
  organizations = 'organizations',
  organizationsLoadingState = 'organizationsLoadingState',
  products = 'products',
  recommendations = 'recommendations',
  recommendationsLoadingState = 'recommendationsLoadingState',
  rudderstackAnonymousId = 'rudderstackAnonymousId',
  states = 'states',
  user = 'user',
  userProfile = 'userProfile',
  waterbodies = 'waterbodies',
  waterbodiesLoadingState = 'waterbodiesLoadingState',
}

export const initialState = {
  [StateKeys.brands]: [] as Brand[],
  [StateKeys.experimentUuid]: null as string,
  [StateKeys.experimentGroupReady]: false,
  [StateKeys.favoriteAmbassadors]: [] as AmbassadorDetail[],
  [StateKeys.favoriteAmbassadorsLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.fishingReports]: [] as FishingReportWithWaterbodyDetail[],
  [StateKeys.fishingReportsLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.loadingState]: LoadingState.NOT_STARTED,
  [StateKeys.orderProducts]: [] as Product[],
  [StateKeys.orders]: [] as Order[],
  [StateKeys.ordersLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.organizations]: [] as Organization[],
  [StateKeys.organizationsLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.products]: {
    wishlist: [] as Product[],
  },
  [StateKeys.recommendations]: [] as Product[],
  [StateKeys.recommendationsLoadingState]: LoadingState.NOT_STARTED,
  [StateKeys.rudderstackAnonymousId]: null as string,
  [StateKeys.states]: [] as string[],
  [StateKeys.user]: null as UserPrivate,
  [StateKeys.userProfile]: null as UserPrivate,
  [StateKeys.waterbodies]: [] as WaterbodyDetail[],
  [StateKeys.waterbodiesLoadingState]: LoadingState.NOT_STARTED,
};

// ========================================================================== //
// Selectors
// ========================================================================== //

export const getLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.loadingState];
export const getUser = (state: ApplicationState) => state[reducerName][StateKeys.user];
export const getUserProfile = (state: ApplicationState) =>
  state[reducerName][StateKeys.userProfile];
export const getUserWaterbodiesLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.waterbodiesLoadingState];
export const getUserWaterbodies = (state: ApplicationState) =>
  state[reducerName][StateKeys.waterbodies];
export const getUserRecommendations = (state: ApplicationState) =>
  state[reducerName][StateKeys.recommendations];
export const getUserRecommendationsLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.recommendationsLoadingState];
export const isUserPremium = createSelector([getUser], (user) => {
  return user != null && user.premium;
});
export const isUserAdmin = createSelector([getUser], (user) => {
  return user != null && user.admin;
});
export const getUserOrders = (state: ApplicationState) => state[reducerName][StateKeys.orders];
export const getUserOrderProducts = (state: ApplicationState) =>
  state[reducerName][StateKeys.orderProducts];
export const getUserOrdersLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.ordersLoadingState];
export const getUserFishingReports = (state: ApplicationState) =>
  state[reducerName][StateKeys.fishingReports];
export const getUserFishingReportsLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.fishingReportsLoadingState];
export const getFavoriteAmbassadors = (state: ApplicationState) =>
  state[reducerName][StateKeys.favoriteAmbassadors];
export const getFavoriteAmbassadorsLoadingState = (state: ApplicationState) =>
  state[reducerName][StateKeys.favoriteAmbassadorsLoadingState];
export const getUserBrands = (state: ApplicationState) => state[reducerName][StateKeys.brands];
export const getUserStates = (state: ApplicationState) => state[reducerName][StateKeys.states];
export const getUserProducts = (state: ApplicationState) => state[reducerName][StateKeys.products];
export const getUserOrganizations = (state: ApplicationState) =>
  state[reducerName][StateKeys.organizations];
export const getExperimentUuid = (state: ApplicationState) =>
  state[reducerName][StateKeys.experimentUuid];
// this is used to create experiment users on the server
export const getExperimentGroupReady = (state: ApplicationState) =>
  state[reducerName][StateKeys.experimentGroupReady];
export const getRudderstackAnonymousId = (state: ApplicationState) =>
  state[reducerName][StateKeys.rudderstackAnonymousId];

// ========================================================================== //
// Reducers
// ========================================================================== //

export default function userReducer(
  state = initialState,
  action: UserActions | AuthActions | UserProfileActions | WaterbodyActions | OrganizationsActions
) {
  switch (action.type) {
    case ReduxActions.USER_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.PENDING,
      };

    case ReduxActions.USER_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.DONE,
        [StateKeys.user]: action.payload.data,
        [StateKeys.experimentUuid]: action.payload.data.experiment_uuid,
        [StateKeys.experimentGroupReady]: true,
      };

    case ReduxActions.USER_FETCH_ERROR:
      return {
        ...state,
        [StateKeys.loadingState]: LoadingState.ERROR,
      };

    case ReduxActions.USER_WATERBODIES_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.waterbodiesLoadingState]: LoadingState.PENDING,
      };

    case ReduxActions.USER_WATERBODIES_FETCH_SUCCESS: {
      const waterbodies = action.payload.data;
      const waterbodyStates = _.flatten(waterbodies.map((w) => w.locales.map((l) => l.state.abbr)));

      return {
        ...state,
        [StateKeys.waterbodiesLoadingState]: LoadingState.DONE,
        [StateKeys.waterbodies]: waterbodies,
        [StateKeys.states]: waterbodyStates,
      };
    }

    case ReduxActions.WATERBODY_FAVORITE_SUCCESS: {
      const waterbody = action.payload.data;
      const userWaterbodies = state[StateKeys.waterbodies];
      const newUserWaterbodies = [...userWaterbodies, waterbody];
      return {
        ...state,
        [StateKeys.waterbodies]: newUserWaterbodies,
      };
    }

    case ReduxActions.WATERBODY_UNFAVORITE_SUCCESS: {
      const waterbody = action.payload.data;
      const userWaterbodies = state[StateKeys.waterbodies];
      const newUserWaterbodies = userWaterbodies.filter(
        (userWaterbody) => userWaterbody.waterbody_id !== waterbody.waterbody_id
      );
      return {
        ...state,
        [StateKeys.waterbodies]: newUserWaterbodies,
      };
    }

    case ReduxActions.USER_WATERBODY_EVENT_NOTIFICATIONS_UPDATE_SUCCESS: {
      const waterbody = action.payload.data;
      const userWaterbodies = state[StateKeys.waterbodies];
      const newWaterbodies = _.cloneDeep(userWaterbodies);
      const waterbodyToUpdate = newWaterbodies.find((w) => {
        return w.id === waterbody.id;
      });
      waterbodyToUpdate.user_event_notifications = waterbody.user_event_notifications;
      return {
        ...state,
        [StateKeys.waterbodies]: newWaterbodies,
      };
    }

    case ReduxActions.USER_RECOMMENDATIONS_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.recommendationsLoadingState]: LoadingState.PENDING,
      };

    case ReduxActions.USER_RECOMMENDATIONS_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.recommendationsLoadingState]: LoadingState.DONE,
        [StateKeys.recommendations]: action.payload.data,
      };

    case ReduxActions.USER_RECOMMENDATIONS_REMOVE_PRODUCT: {
      const recommendations = state[StateKeys.recommendations].filter(
        (recommendation) => recommendation.sku !== action.payload.sku
      );
      return {
        ...state,
        [StateKeys.recommendations]: recommendations,
      };
    }

    case ReduxActions.USER_PRODUCTS_FETCH_SUCCESS:
    case ReduxActions.USER_PRODUCTS_CREATE_SUCCESS:
    case ReduxActions.USER_PRODUCTS_DELETE_SUCCESS:
      return {
        ...state,
        [StateKeys.products]: action.payload.data,
      };

    case ReduxActions.USER_ORDERS_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.ordersLoadingState]: LoadingState.PENDING,
      };

    case ReduxActions.USER_ORDERS_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.orders]: action.payload.data.orders,
        [StateKeys.orderProducts]: action.payload.data.products,
        [StateKeys.ordersLoadingState]: LoadingState.DONE,
      };

    //
    // Fishing Reports
    //

    case ReduxActions.USER_FISHING_REPORT_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.fishingReportsLoadingState]: LoadingState.PENDING,
      };

    case ReduxActions.USER_FISHING_REPORT_FETCH_SUCCESS:
      return {
        ...state,
        [StateKeys.fishingReports]: action.payload.data,
        [StateKeys.fishingReportsLoadingState]: LoadingState.DONE,
      };

    case ReduxActions.USER_FISHING_REPORT_UPDATE_SUCCESS: {
      const fishingReports = [...state[StateKeys.fishingReports]];
      const newFishingReport = action.payload.data;

      const matchIdx = _.findIndex(fishingReports, { id: newFishingReport.id });
      fishingReports.splice(matchIdx, 1, newFishingReport);
      return {
        ...state,
        [StateKeys.fishingReports]: fishingReports,
      };
    }

    case ReduxActions.USER_FISHING_REPORT_DELETE_SUCCESS: {
      const fishingReports = state[StateKeys.fishingReports];
      return {
        ...state,
        [StateKeys.fishingReports]: fishingReports.filter((hb) => hb.id !== action.payload.id),
      };
    }

    //
    // User profile
    //
    case ReduxActions.USER_USER_PROFILE_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.userProfile]: action.payload.data,
      };
    }

    case ReduxActions.USER_PROFILE_UPDATE_SUCCESS: {
      return {
        ...state,
        [StateKeys.userProfile]: action.payload.data,
      };
    }

    //
    // Ambassadors
    //

    case ReduxActions.USER_AMBASSADORS_FETCH_PENDING: {
      return {
        ...state,
        [StateKeys.favoriteAmbassadorsLoadingState]: LoadingState.PENDING,
      };
    }

    case ReduxActions.USER_AMBASSADORS_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.favoriteAmbassadors]: action.payload.data,
        [StateKeys.favoriteAmbassadorsLoadingState]: LoadingState.DONE,
      };
    }

    case ReduxActions.USER_AMBASSADORS_FETCH_ERROR: {
      return {
        ...state,
        [StateKeys.favoriteAmbassadorsLoadingState]: LoadingState.ERROR,
      };
    }

    //
    // Brands
    //
    case ReduxActions.USER_BRANDS_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.brands]: action.payload.data,
      };
    }

    //
    // Auth
    //

    case ReduxActions.AUTH_LOGIN_SUCCESS:
    case ReduxActions.AUTH_SIGNUP_SUCCESS: {
      return {
        ...state,
        [StateKeys.user]: action.payload.data.user,
        [StateKeys.experimentUuid]: action.payload.data.user.experiment_uuid,
        [StateKeys.experimentGroupReady]: true,
      };
    }

    case ReduxActions.AUTH_REFRESH_ERROR:
    case ReduxActions.AUTH_LOGOUT:
      return {
        ...initialState,
        [StateKeys.experimentUuid]: state[StateKeys.experimentUuid],
        [StateKeys.experimentGroupReady]: state[StateKeys.experimentGroupReady],
      };

    //
    // Orgs
    //

    case ReduxActions.ORGANIZATION_JOIN_SUCCESS:
    case ReduxActions.ORGANIZATION_LEAVE_SUCCESS: {
      return {
        ...state,
        [StateKeys.organizations]: action.payload.data,
      };
    }

    case ReduxActions.USER_ORGANIZATIONS_FETCH_PENDING:
      return {
        ...state,
        [StateKeys.organizationsLoadingState]: LoadingState.PENDING,
      };

    case ReduxActions.USER_ORGANIZATIONS_FETCH_SUCCESS: {
      return {
        ...state,
        [StateKeys.organizationsLoadingState]: LoadingState.DONE,
        [StateKeys.organizations]: action.payload.data,
      };
    }

    case ReduxActions.USER_EXPERIMENT_IDS_SET: {
      const { uuid } = action.payload;
      return {
        ...state,
        [StateKeys.experimentUuid]: uuid,
        [StateKeys.experimentGroupReady]: true,
      };
    }

    case ReduxActions.USER_EXPERIMENT_GROUP_ID_READY: {
      return {
        ...state,
        [StateKeys.experimentGroupReady]: true,
      };
    }

    case ReduxActions.RUDDERSTACK_ANONYMOUS_ID_SET: {
      return {
        ...state,
        [StateKeys.rudderstackAnonymousId]: action.payload,
      };
    }

    case ReduxActions.USER_PROFILE_FAVORITE_SUCCESS: {
      const uniqueFavoriteAmbassadors = _.uniqBy(
        [...state[StateKeys.favoriteAmbassadors], action.payload.data],
        'slug'
      );
      return {
        ...state,
        [StateKeys.favoriteAmbassadors]: uniqueFavoriteAmbassadors,
      };
    }
    case ReduxActions.USER_PROFILE_UNFAVORITE_SUCCESS: {
      const ambassadorToRemove = action.payload.data.slug;
      const existingAmbassadors = state[StateKeys.favoriteAmbassadors];
      const updatedAmbassadors = existingAmbassadors.filter((a) => a.slug !== ambassadorToRemove);
      return {
        ...state,
        [StateKeys.favoriteAmbassadors]: updatedAmbassadors,
      };
    }

    case ReduxActions.USER_MAP_TEMPERATURE_ACTIVATE_SUCCESS: {
      return {
        ...state,
        [StateKeys.user]: action.payload.data,
      };
    }

    default:
      return state;
  }
}

// ========================================================================== //
// Actions
// ========================================================================== //

export const UserActions = {
  USER_FETCH_PENDING: () => createAction(ReduxActions.USER_FETCH_PENDING),
  USER_FETCH_SUCCESS: (response: OmniaResponse<UserPrivate>) =>
    createAction(ReduxActions.USER_FETCH_SUCCESS, response),
  USER_FETCH_ERROR: (err: any) => createAction(ReduxActions.USER_FETCH_ERROR, err),

  USER_WATERBODIES_FETCH_PENDING: () => createAction(ReduxActions.USER_WATERBODIES_FETCH_PENDING),
  USER_WATERBODIES_FETCH_SUCCESS: (response: OmniaResponse<WaterbodyDetail[]>) =>
    createAction(ReduxActions.USER_WATERBODIES_FETCH_SUCCESS, response),
  USER_WATERBODIES_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.USER_WATERBODIES_FETCH_ERROR, err),

  USER_ORGANIZATIONS_FETCH_PENDING: () =>
    createAction(ReduxActions.USER_ORGANIZATIONS_FETCH_PENDING),
  USER_ORGANIZATIONS_FETCH_SUCCESS: (response: OmniaResponse<Organization[]>) =>
    createAction(ReduxActions.USER_ORGANIZATIONS_FETCH_SUCCESS, response),
  USER_ORGANIZATIONS_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.USER_ORGANIZATIONS_FETCH_ERROR, err),

  USER_RECOMMENDATIONS_FETCH_PENDING: () =>
    createAction(ReduxActions.USER_RECOMMENDATIONS_FETCH_PENDING),
  USER_RECOMMENDATIONS_FETCH_SUCCESS: (response: OmniaResponse<Product[]>) =>
    createAction(ReduxActions.USER_RECOMMENDATIONS_FETCH_SUCCESS, response),
  USER_RECOMMENDATIONS_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.USER_RECOMMENDATIONS_FETCH_ERROR, err),
  USER_RECOMMENDATIONS_REMOVE_PRODUCT: (sku: string) =>
    createAction(ReduxActions.USER_RECOMMENDATIONS_REMOVE_PRODUCT, { sku }),

  USER_PRODUCTS_FETCH_PENDING: () => createAction(ReduxActions.USER_PRODUCTS_FETCH_PENDING),
  USER_PRODUCTS_FETCH_SUCCESS: (response: OmniaResponse<UserLists>) =>
    createAction(ReduxActions.USER_PRODUCTS_FETCH_SUCCESS, response),
  USER_PRODUCTS_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.USER_PRODUCTS_FETCH_ERROR, err),

  USER_PRODUCTS_CREATE_PENDING: () => createAction(ReduxActions.USER_PRODUCTS_CREATE_PENDING),
  USER_PRODUCTS_CREATE_SUCCESS: (response: OmniaResponse<UserLists>) =>
    createAction(ReduxActions.USER_PRODUCTS_CREATE_SUCCESS, response),
  USER_PRODUCTS_CREATE_ERROR: (err: any) =>
    createAction(ReduxActions.USER_PRODUCTS_CREATE_ERROR, err),

  USER_PRODUCTS_DELETE_PENDING: () => createAction(ReduxActions.USER_PRODUCTS_DELETE_PENDING),
  USER_PRODUCTS_DELETE_SUCCESS: (response: OmniaResponse<UserLists>) =>
    createAction(ReduxActions.USER_PRODUCTS_DELETE_SUCCESS, response),
  USER_PRODUCTS_DELETE_ERROR: (err: any) =>
    createAction(ReduxActions.USER_PRODUCTS_DELETE_ERROR, err),

  USER_ORDERS_FETCH_PENDING: () => createAction(ReduxActions.USER_ORDERS_FETCH_PENDING),
  USER_ORDERS_FETCH_SUCCESS: (response: OmniaResponse<FetchUserOrdersResponse>) =>
    createAction(ReduxActions.USER_ORDERS_FETCH_SUCCESS, response),
  USER_ORDERS_FETCH_ERROR: (err: any) => createAction(ReduxActions.USER_ORDERS_FETCH_ERROR, err),

  //
  // Fishing Report
  //

  USER_FISHING_REPORT_FETCH_PENDING: () =>
    createAction(ReduxActions.USER_FISHING_REPORT_FETCH_PENDING),
  USER_FISHING_REPORT_FETCH_SUCCESS: (
    response: OmniaResponse<FishingReportWithWaterbodyDetail[]>
  ) => createAction(ReduxActions.USER_FISHING_REPORT_FETCH_SUCCESS, response),
  USER_FISHING_REPORT_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.USER_FISHING_REPORT_FETCH_ERROR, err),

  USER_FISHING_REPORT_UPDATE_PENDING: () =>
    createAction(ReduxActions.USER_FISHING_REPORT_UPDATE_PENDING),
  USER_FISHING_REPORT_UPDATE_SUCCESS: (response: OmniaResponse<FishingReportWithWaterbodyDetail>) =>
    createAction(ReduxActions.USER_FISHING_REPORT_UPDATE_SUCCESS, response),
  USER_FISHING_REPORT_UPDATE_ERROR: (err: any) =>
    createAction(ReduxActions.USER_FISHING_REPORT_UPDATE_ERROR, err),

  USER_FISHING_REPORT_DELETE_PENDING: () =>
    createAction(ReduxActions.USER_FISHING_REPORT_DELETE_PENDING),
  USER_FISHING_REPORT_DELETE_SUCCESS: (response: { id: number }) =>
    createAction(ReduxActions.USER_FISHING_REPORT_DELETE_SUCCESS, response),
  USER_FISHING_REPORT_DELETE_ERROR: (err: any) =>
    createAction(ReduxActions.USER_FISHING_REPORT_DELETE_ERROR, err),

  //
  // User profiles
  //
  USER_AMBASSADORS_FETCH_PENDING: () => createAction(ReduxActions.USER_AMBASSADORS_FETCH_PENDING),
  USER_AMBASSADORS_FETCH_SUCCESS: (response: OmniaResponse<AmbassadorDetail[]>) =>
    createAction(ReduxActions.USER_AMBASSADORS_FETCH_SUCCESS, response),
  USER_AMBASSADORS_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.USER_AMBASSADORS_FETCH_ERROR, err),

  USER_USER_PROFILE_FETCH_PENDING: () => createAction(ReduxActions.USER_USER_PROFILE_FETCH_PENDING),
  USER_USER_PROFILE_FETCH_SUCCESS: (response: OmniaResponse<UserPrivate>) =>
    createAction(ReduxActions.USER_USER_PROFILE_FETCH_SUCCESS, response),
  USER_USER_PROFILE_FETCH_ERROR: (err: any) =>
    createAction(ReduxActions.USER_USER_PROFILE_FETCH_ERROR, err),

  //
  // Brands
  //
  USER_BRANDS_FETCH_PENDING: () => createAction(ReduxActions.USER_BRANDS_FETCH_PENDING),
  USER_BRANDS_FETCH_SUCCESS: (response: OmniaResponse<Brand[]>) =>
    createAction(ReduxActions.USER_BRANDS_FETCH_SUCCESS, response),
  USER_BRANDS_FETCH_ERROR: (err: any) => createAction(ReduxActions.USER_BRANDS_FETCH_ERROR, err),

  USER_EXPERIMENT_IDS_SET: ({ uuid }: { uuid: string }) =>
    createAction(ReduxActions.USER_EXPERIMENT_IDS_SET, { uuid }),
  USER_EXPERIMENT_GROUP_ID_READY: () => createAction(ReduxActions.USER_EXPERIMENT_GROUP_ID_READY),

  // Water temp layer
  USER_MAP_TEMPERATURE_ACTIVATE_SUCCESS: (response: OmniaResponse<UserPrivate>) =>
    createAction(ReduxActions.USER_MAP_TEMPERATURE_ACTIVATE_SUCCESS, response),

  // Waterbody Event Notifications
  USER_WATERBODY_EVENT_NOTIFICATIONS_UPDATE_SUCCESS: (response: OmniaResponse<WaterbodyDetail>) =>
    createAction(ReduxActions.USER_WATERBODY_EVENT_NOTIFICATIONS_UPDATE_SUCCESS, response),

  // analytics
  RUDDERSTACK_ANONYMOUS_ID_SET: (id: string) =>
    createAction(ReduxActions.RUDDERSTACK_ANONYMOUS_ID_SET, id),
};
export type UserActions = ActionsUnion<typeof UserActions>;

export function fetchUser(): RequestThunk {
  return (dispatch) => {
    dispatch(UserActions.USER_FETCH_PENDING());

    return apiV1
      .userFetch()
      .then((response) => {
        return dispatch(UserActions.USER_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchUser`, error);
        return dispatch(UserActions.USER_FETCH_ERROR(error));
      });
  };
}

export function fetchUserWaterbodies(): RequestThunk {
  return (dispatch) => {
    dispatch(UserActions.USER_WATERBODIES_FETCH_PENDING());

    return apiV1
      .userWaterbodiesFetch()
      .then((response) => {
        return dispatch(UserActions.USER_WATERBODIES_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchUserWaterbodies`, error);
        return dispatch(UserActions.USER_WATERBODIES_FETCH_ERROR(error));
      });
  };
}

export function fetchUserOrganizations(): RequestThunk {
  return (dispatch) => {
    dispatch(UserActions.USER_ORGANIZATIONS_FETCH_PENDING());
    return apiV1
      .userOrganizationFetch()
      .then((response) => {
        return dispatch(UserActions.USER_ORGANIZATIONS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchUserOrganizations`, error);
        return dispatch(UserActions.USER_ORGANIZATIONS_FETCH_ERROR(error));
      });
  };
}

export interface UserLists {
  wishlist: Product[];
}

export function fetchUserProducts(): RequestThunk {
  return (dispatch) => {
    dispatch(UserActions.USER_PRODUCTS_FETCH_PENDING());

    return apiV1
      .userProductsFetch()
      .then((response) => {
        return dispatch(UserActions.USER_PRODUCTS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchUserProducts`, error);
        return dispatch(UserActions.USER_PRODUCTS_FETCH_ERROR(error));
      });
  };
}

export function createUserProductActions(skus: string[], type: ListTypes): RequestThunk {
  return (dispatch) => {
    dispatch(UserActions.USER_PRODUCTS_CREATE_PENDING());

    return apiV1
      .userProductsCreate({
        skus,
        type,
      })
      .then((response) => {
        return dispatch(UserActions.USER_PRODUCTS_CREATE_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: createUserProductActions: ${skus}, ${type}`, error);
        return dispatch(UserActions.USER_PRODUCTS_CREATE_ERROR(error));
      });
  };
}

export function deleteUserProductAction(sku: string, type: ListTypes): RequestThunk {
  return (dispatch) => {
    dispatch(UserActions.USER_PRODUCTS_DELETE_PENDING());

    return apiV1
      .userProductDelete({
        sku,
        type,
      })
      .then((response) => {
        return dispatch(UserActions.USER_PRODUCTS_DELETE_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: deleteUserProductAction: ${sku}, ${type}`, error);
        return dispatch(UserActions.USER_PRODUCTS_DELETE_ERROR(error));
      });
  };
}

export interface FetchUserOrdersResponse {
  orders: Order[];
  products: Product[];
}

export function fetchUserOrders(): RequestThunk {
  return (dispatch) => {
    dispatch(UserActions.USER_ORDERS_FETCH_PENDING());

    return apiV1
      .userOrdersFetch()
      .then((response) => {
        return dispatch(UserActions.USER_ORDERS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchUserOrders`, error);
        return dispatch(UserActions.USER_ORDERS_FETCH_ERROR(error));
      });
  };
}

//
// Fishing Reports
//

export function fetchUserFishingReports(): RequestThunk {
  return (dispatch) => {
    dispatch(UserActions.USER_FISHING_REPORT_FETCH_PENDING());

    return apiV1
      .userFishingReportsFetch()
      .then((response) => {
        return dispatch(UserActions.USER_FISHING_REPORT_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchUserFishingReports`, error);
        return dispatch(UserActions.USER_FISHING_REPORT_FETCH_ERROR(error));
      });
  };
}

export function deleteFishingReport(id: number): RequestThunk {
  return (dispatch) => {
    dispatch(UserActions.USER_FISHING_REPORT_DELETE_PENDING());

    return apiV1
      .userFishingReportDelete(id)
      .then(() => {
        return dispatch(UserActions.USER_FISHING_REPORT_DELETE_SUCCESS({ id }));
      })
      .catch((error) => {
        errorHandler(`ERROR: deleteUserFishingReport: ${id}`, error);
        return dispatch(UserActions.USER_FISHING_REPORT_DELETE_ERROR(error));
      });
  };
}

//
// Favorite User Profiles
//

export function fetchUserAmbassadors(params: {
  exclude?: AmbassadorDetailExcludes[];
}): RequestThunk {
  return (dispatch) => {
    dispatch(UserActions.USER_AMBASSADORS_FETCH_PENDING());

    return apiV1
      .userAmbassadorsFetch(params)
      .then((response) => {
        return dispatch(UserActions.USER_AMBASSADORS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchUserAmbassadors`, error);
        return dispatch(UserActions.USER_AMBASSADORS_FETCH_ERROR(error));
      });
  };
}

//
// User Profile
//

export function fetchCurrentUserProfile(): RequestThunk {
  return (dispatch) => {
    dispatch(UserActions.USER_USER_PROFILE_FETCH_PENDING());

    return apiV1
      .userUserProfileFetch()
      .then((response) => {
        return dispatch(UserActions.USER_USER_PROFILE_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchCurrentUserProfile`, error);
        return dispatch(UserActions.USER_USER_PROFILE_FETCH_ERROR(error));
      });
  };
}

//
// Brands
//

export function fetchUserBrands(): RequestThunk {
  return (dispatch) => {
    dispatch(UserActions.USER_BRANDS_FETCH_PENDING());

    return apiV1
      .userBrandsFetch()
      .then((response) => {
        return dispatch(UserActions.USER_BRANDS_FETCH_SUCCESS(response.data));
      })
      .catch((error) => {
        errorHandler(`ERROR: fetchUserBrands`, error);
        return dispatch(UserActions.USER_BRANDS_FETCH_ERROR(error));
      });
  };
}
