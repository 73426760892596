import { AlgoliaSpecies, Species } from '@omniafishing/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { RoutePaths } from '../../routes';
import styles from './search_species.less';

interface SearchSpeciesProps {
  species: AlgoliaSpecies | Species;
  onClick: (target_url?: string) => void;
}

function isTypeAlgoliaSpecies(value: AlgoliaSpecies | Species): value is AlgoliaSpecies {
  return value.hasOwnProperty('_highlightResult');
}

export const SearchSpecies = (props: SearchSpeciesProps) => {
  const { species, onClick } = props;

  return (
    <Link
      to={`${RoutePaths.SPECIES}/${species.url_path}`}
      className={styles.link}
      onClick={() => onClick(`${RoutePaths.SPECIES}/${species.url_path}`)}
    >
      {isTypeAlgoliaSpecies(species) ? (
        <span
          className={styles.name}
          dangerouslySetInnerHTML={{ __html: species._highlightResult.display_name.value }}
        />
      ) : (
        <span className={styles.name}>{species.display_name}</span>
      )}
    </Link>
  );
};
