import { AlgoliaProductFamily, ProductFamily } from '@omniafishing/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { OmniaUrls } from '../../lib/urls';
import { WebAnalytics } from '../../lib/web_analytics';
import styles from './search_product_family.less';

interface SearchProductProps {
  productFamily: AlgoliaProductFamily | ProductFamily;
  onClick: (target_url?: string) => void;
  position: number;
  list_uuid: string;
}

function isTypeAlgoliaProductFamily(
  value: AlgoliaProductFamily | ProductFamily
): value is AlgoliaProductFamily {
  return value.hasOwnProperty('_highlightResult');
}

export const SearchProductFamily = (props: SearchProductProps) => {
  const { productFamily, onClick, position, list_uuid } = props;

  const title = isTypeAlgoliaProductFamily(productFamily)
    ? productFamily._highlightResult.title.value
    : productFamily.title;

  return (
    <Link
      to={OmniaUrls.productFamily(productFamily.handle)}
      className={styles.link}
      onClick={() => {
        onClick(OmniaUrls.productFamily(productFamily.handle));
        WebAnalytics.productClick({
          productOrProductFamily: productFamily as ProductFamily,
          show_modal: false,
          position,
          list_uuid,
        });
      }}
    >
      <img src={setShopifyImgWidth(productFamily.img_url, 100)} loading="lazy" />
      <span className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
    </Link>
  );
};
