import {
  CloseOutlined,
  InfoCircleOutlined,
  LockOutlined,
  SearchOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  FishingReport,
  OmniaResponse,
  Product,
  SeasonSpeciesTechniquesFetchParams,
  WaterbodyDetail,
} from '@omniafishing/core';
import {
  Alert,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  InputNumber,
  Select,
  Tooltip,
  Upload,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';
import { UploadChangeParam } from 'antd/lib/upload';
import classNames from 'classnames';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getApiBaseUrlV1 } from '../../axios_omnia';
import { isError, LoadingState } from '../../constants/loading_state';
import { useFlickingRenderRef } from '../../hooks/use_flicking_render_ref';
import { useResponsive } from '../../hooks/use_responsive';
import { apiV1 } from '../../lib/api';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { isAmbassador } from '../../lib/user';
import { getAccessToken } from '../../redux/auth';
import {
  getForages,
  getSeasons,
  getSpecies,
  getStructures,
  getTechniques,
} from '../../redux/reference_data';
import { getUser } from '../../redux/user';
import OmniaCarousel from '../carousel/omnia_carousel';
import { ImageLoaderWithState } from '../image_loader/image_loader_with_state';
import { OmniaButton } from '../omnia_button/omnia_button';
import {
  buildValueLabel,
  getProductsValueLabels,
  ProductSearch,
  ProductSelectValue,
} from '../product_search/product_search';
import { SeasonIcon } from '../season_icon/season_icon';
import SvgFire from '../svg/fire';
import {
  WaterbodySearchSelect,
  WaterbodySelectValue,
} from '../waterbody_search_select/waterbody_search_select';
import styles from './fishing_report_form.less';
import { AuthError } from './fishing_report_modal';

dayjs.extend(dayOfYear);
export interface FishingReportFormProps {
  authError?: AuthError;
  featuredProductFromQueryParams?: Product;
  fishingReport: FishingReport;
  formRef: React.RefObject<FormInstance>;
  isEdit: boolean;
  loadingState: LoadingState;
  onUploadDone?: () => void;
  onUploadStart?: () => void;
  onSelectedHotbaitChange?: (product: Product) => void;
  onSelectedProductsChange?: (products: Product[]) => void;
  selectedHotbait: Product;
  selectedProducts: Product[];
  waterbody?: WaterbodyDetail;
}

export const formFields = {
  comments: 'comments',
  featured_product: 'featured_product',
  forage: 'forage',
  img: 'img',
  outing_date: 'outing_date',
  password: 'password',
  products: 'products',
  season: 'season',
  species: 'species',
  structure: 'structure',
  style: 'style',
  username: 'username',
  water_temperature: 'water_temperature',
  video_id: 'video_id',
  video: 'video',
  waterbody: 'waterbody',
};

export const FishingReportForm = (props: FishingReportFormProps) => {
  const {
    authError,
    featuredProductFromQueryParams,
    fishingReport,
    formRef,
    isEdit,
    loadingState,
    onUploadDone,
    onUploadStart,
    onSelectedHotbaitChange,
    onSelectedProductsChange,
    selectedHotbait,
    selectedProducts,
    waterbody,
  } = props;

  const techniques = useSelector(getTechniques);
  const structures = useSelector(getStructures);
  const species = useSelector(getSpecies);
  const seasons = useSelector(getSeasons);
  const forages = useSelector(getForages);
  const user = useSelector(getUser);
  const accessToken = useSelector(getAccessToken);
  const isNotLoggedIn = !accessToken;
  const [form] = Form.useForm();
  const multiProductSelectRef = useRef<HTMLDivElement>(null);

  const { isDesktop, isMobile } = useResponsive();

  const [fileList, setFileList] = useState([]);
  const [selectedOutingDate, setSelectedOutingDate] = useState<string>();
  const [waterbodyDetail, setWaterbodyDetail] = useState<WaterbodyDetail>(waterbody || null);
  const [randomKey, setRandomKey] = useState('soon-to-be-a-random-key'); // used to force re-render product search in multi mode thereby closing the dropdown on mobile
  const [formValues, setFormValues] = useState({
    season: null as string,
  });
  const [recommendedTechniqueNames, setRecommendedTechniqueNames] = useState([] as string[]);
  const recommendedTechniques = techniques.filter((t) =>
    recommendedTechniqueNames.includes(t.name)
  );
  const otherTechniques = techniques.filter((t) => !recommendedTechniqueNames.includes(t.name));
  const otherTechniquesSorted = _.sortBy(otherTechniques, 'display_name');

  const selectedSeason = fishingReport?.season;
  const selectedSpecies = fishingReport?.species;
  const selectedTechnique = fishingReport?.technique;
  const selectedStructure = fishingReport?.structure;
  const selectedForage = fishingReport?.forage;
  const outing_date = fishingReport?.outing_date;

  const initialValues: Record<string, any> = isEdit
    ? {
        ...fishingReport,
        [formFields.featured_product]: fishingReport?.featured_product
          ? buildValueLabel(fishingReport.featured_product)
          : null,
        [formFields.products]: fishingReport.list?.products
          ? getProductsValueLabels(fishingReport.list?.products)
          : null,
      }
    : {
        [formFields.comments]: null,
        [formFields.img]: null,
        [formFields.season]: null,
        [formFields.species]: null,
        [formFields.structure]: null,
        [formFields.forage]: null,
        [formFields.outing_date]: null,
        [formFields.style]: null,
        [formFields.video_id]: null,
        [formFields.video]: null,
        [formFields.water_temperature]: null,
        [formFields.featured_product]: featuredProductFromQueryParams
          ? buildValueLabel(featuredProductFromQueryParams)
          : null,
      };

  // synchronize initial product values
  useEffect(() => {
    if (isEdit) {
      const fishingReportListProducts = fishingReport.list?.products;
      onSelectedProductsChange(fishingReportListProducts || []);
      onSelectedHotbaitChange(fishingReport.featured_product || null);
    }
    if (featuredProductFromQueryParams) {
      onSelectedHotbaitChange(featuredProductFromQueryParams);
    }
  }, [fishingReport, featuredProductFromQueryParams?.sku]);

  const formSpecies = waterbody ? waterbody.species : species;

  useEffect(() => {
    if (formValues.season) {
      const params: SeasonSpeciesTechniquesFetchParams = {
        season: formValues.season,
      };
      apiV1.seasonSpeciesTechniquesFetch(params).then((results) => {
        setRecommendedTechniqueNames(results.data.data.techniques);
      });
    }
  }, [formValues.season]);

  const videoAllowed = user != null && isAmbassador(user);

  let messageText;
  switch (authError) {
    case 'email_exists':
      messageText = 'A user with this email already exists.  Log in with your email and password.';
      break;
    case 'invalid_password':
      messageText = 'It looks like the password you entered is incorrect. Please try again.';
      break;
    default:
      messageText = 'Sorry, there was an error submitting your Fishing Report.';
  }

  const errorMessage = <Alert type="error" message={messageText} />;

  const onValuesChange = (changedValues: Store, values: Store) => {
    setFormValues({
      season: values.season,
    });
  };

  const onFileChange = (param: UploadChangeParam) => {
    const { file } = param;
    if (file.status === 'uploading') {
      onUploadStart();
    }
    if (file.status === 'removed') {
      onUploadDone();
      return;
    }
    if (file.status === 'error') {
      onUploadDone();
    }
    if (file.status === 'done') {
      onUploadDone();
      form.setFieldsValue({
        [formFields.video_id]: (file.response as OmniaResponse<{ id: number }>).data.id,
      });
    }
    setFileList([param.file]);
  };

  const hotbaitTooltipContent =
    'A Hotbait is the one bait that caught the biggest or most fish for the conditions during your outing.';

  const otherProductsTooltipContent =
    'These products could be the rod and reel, jig trailers or other gear that helped on your outing.';

  const UserForm = (
    <>
      {authError ? (
        <div className={styles.signupBlurb}>
          <div>
            <strong>Log In To Your Account</strong>
          </div>
        </div>
      ) : (
        <div className={styles.signupBlurb}>
          <div>
            <strong>Create an Account</strong>
          </div>
        </div>
      )}
      <Form.Item
        name={formFields.username}
        rules={[
          {
            type: 'email',
            message: 'Please enter a valid email',
          },
          {
            required: true,
            message: 'Please enter an email address',
          },
        ]}
      >
        <Input
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Email"
          type="email"
          size="large"
        />
      </Form.Item>
      <Form.Item
        className={styles.passwordField}
        name={formFields.password}
        rules={[
          {
            required: true,
            message: 'Please enter a password that is at least 6 characters',
          },
          {
            min: 6,
            message: 'Passwords must be 6 characters',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Password"
          type="password"
          size="large"
        />
      </Form.Item>
    </>
  );

  const getOutingDateSeasonName = (date: string) => {
    const outingDate = waterbodyDetail.season_zones.find((sz) => {
      const calendarDayOfYear = dayjs(date).dayOfYear();
      return sz.start_day <= calendarDayOfYear && calendarDayOfYear <= sz.end_day;
    });
    return outingDate.season;
  };

  useEffect(() => {
    if (selectedOutingDate && waterbodyDetail) {
      const outingDateSeasonName = getOutingDateSeasonName(selectedOutingDate);
      form.setFieldsValue({ [formFields.season]: outingDateSeasonName });
    }
  }, [waterbodyDetail, selectedOutingDate]);

  const onDateChange: DatePickerProps['onChange'] = (value, dateString) => {
    if (!dateString) {
      form.setFieldsValue({ [formFields.season]: undefined });
      setSelectedOutingDate(undefined);
      return;
    }
    setSelectedOutingDate(dateString);
    if (waterbodyDetail) {
      const outingDateSeasonName = getOutingDateSeasonName(dateString);
      form.setFieldsValue({ [formFields.season]: outingDateSeasonName });
    }
  };

  const onWaterbodyChange = async (waterbodySelectValue: WaterbodySelectValue | null) => {
    if (!waterbodySelectValue) {
      setWaterbodyDetail(null);
      return;
    }
    const waterbodySlug = waterbodySelectValue.label.props['data-result'].url_slug;
    const result = await apiV1.waterbodyFetch(waterbodySlug);
    setWaterbodyDetail(result.data.data);
  };

  const outingDateSeason =
    selectedOutingDate &&
    waterbodyDetail &&
    seasons.find((s) => s.name === getOutingDateSeasonName(selectedOutingDate));

  // annoying but needed effect to keep form and productSearch in sync
  useEffect(() => {
    if (selectedHotbait !== null) {
      const productSelectValue = buildValueLabel(selectedHotbait);
      form.setFieldsValue({
        [formFields.featured_product]: productSelectValue,
      });
    }
  }, [selectedHotbait, form]);

  const handleHotbaitSelect = (selectProductItem: ProductSelectValue) => {
    const productToAdd = selectProductItem.label.props['data-result'];
    onSelectedHotbaitChange(productToAdd);
    form.setFieldsValue({ [formFields.featured_product]: selectProductItem.value });
  };

  const handleHotbaitCardDismiss = () => {
    onSelectedHotbaitChange(null);
    form.setFieldsValue({ [formFields.featured_product]: null });
  };

  const hotbaitFormItem = (
    <>
      <Form.Item
        name={formFields.featured_product}
        label={
          <span>
            Hotbait{' '}
            <Tooltip title={hotbaitTooltipContent}>
              <InfoCircleOutlined style={{ fontSize: '12px' }} />
            </Tooltip>
          </span>
        }
        className={styles.item}
        rules={[{ required: !isEdit, message: 'Please choose a product as your hotbait' }]}
      >
        <ProductSearch
          allowClear
          allowHiddenProducts
          filterOption={(input, option) => {
            return selectedHotbait?.sku !== option.value;
          }}
          key={selectedHotbait?.sku}
          onClear={handleHotbaitCardDismiss}
          onSelect={handleHotbaitSelect}
          placeholderText="Select the best bait of your outing"
          scopeSearchToBaitsOnly
        />
      </Form.Item>
      {selectedHotbait && (
        <div className={styles.productCard__hotbait}>
          <CloseOutlined className={styles.closeIcon} onClick={handleHotbaitCardDismiss} />
          <span className={styles.hotbaitContainer}>
            <SvgFire className={styles.fire} />
            Hotbait
          </span>
          <div className={styles.productImgContainer}>
            <ImageLoaderWithState
              src={setShopifyImgWidth(selectedHotbait.img_url, 300)}
              alt={selectedHotbait.title}
              className={styles.productImg}
              key={selectedHotbait?.sku}
              loadingClassName={styles.productImgLoading}
            />
          </div>
          <p className={styles.productTitle}>{selectedHotbait.title}</p>
        </div>
      )}
    </>
  );

  const forceCloseMultiSelectDropdown = () => {
    if (isDesktop) {
      multiProductSelectRef.current.querySelector('input').blur(); // ants provided blur method on ref doesn't work
    } else if (isMobile) {
      setRandomKey(Math.random().toString()); // force close on mobile. only viewable on actual devices
    }
  };

  const setMultiSelectProducts = (products: Product[]) => {
    onSelectedProductsChange(products);
    form.setFieldsValue({ [formFields.products]: getProductsValueLabels(products) });
  };

  const handleMultiProductsSelect = (productItem: ProductSelectValue) => {
    const productToAdd = productItem.label.props['data-result'];
    const updatedProducts = [productToAdd, ...selectedProducts];

    setMultiSelectProducts(updatedProducts);
    forceCloseMultiSelectDropdown();
  };

  const handleProductCardDeselect = (productItem: ProductSelectValue) => {
    const productToRemove = productItem.label.props['data-result'];
    const updatedSelectedProducts = selectedProducts.filter((p) => p.sku !== productToRemove.sku);

    setMultiSelectProducts(updatedSelectedProducts);
    forceCloseMultiSelectDropdown();
  };

  const handleProductCardDismiss = (product: Product) => {
    const updatedSelectedProducts = selectedProducts.filter((p) => p.sku !== product.sku);
    setMultiSelectProducts(updatedSelectedProducts);
  };

  const { isRendered, renderCallbackRef } = useFlickingRenderRef();

  const productsFormItem = (
    <>
      <Form.Item
        name={formFields.products}
        label={
          <span>
            Other Products You Used{' '}
            <Tooltip title={otherProductsTooltipContent}>
              <InfoCircleOutlined style={{ fontSize: '12px' }} />
            </Tooltip>
          </span>
        }
        className={styles.item}
        rules={[
          {
            type: 'array',
            message: 'Please select a product if you would like to add one',
            warningOnly: true,
          },
        ]}
      >
        <div ref={multiProductSelectRef}>
          <ProductSearch
            allowHiddenProducts
            mode="multiple"
            onSelect={handleMultiProductsSelect}
            onDeselect={handleProductCardDeselect}
            placeholderText="Select other gear that helped you"
            key={randomKey} // hack to force close dropdown onSelect when in multiple.
            value={(selectedProducts || []).map((product) => buildValueLabel(product))}
          />
        </div>
      </Form.Item>
      {selectedProducts.length > 0 && (
        <section className={styles.carouselWrapper} ref={renderCallbackRef}>
          <OmniaCarousel
            key={selectedProducts.map((product) => product.sku).join() + isRendered}
            arrows={true}
            fadedEdges
            adaptive
            newArrows
            resizeOnContentsReady
            className={styles.carousel}
          >
            {selectedProducts.map((product) => {
              return (
                <div className={styles.productCardWrapper} key={product.sku}>
                  <div className={styles.productCard}>
                    <CloseOutlined
                      className={styles.closeIcon}
                      onClick={() => handleProductCardDismiss(product)}
                    />
                    <div className={styles.productImgContainer}>
                      <ImageLoaderWithState
                        src={setShopifyImgWidth(product.img_url, 300)}
                        alt={product.title}
                        className={styles.productImg}
                        loadingClassName={styles.productImgLoading}
                      />
                    </div>
                    <p className={styles.productTitle}>{product.title}</p>
                  </div>
                </div>
              );
            })}
          </OmniaCarousel>
        </section>
      )}
    </>
  );

  return (
    <Form
      ref={formRef}
      form={form}
      layout="vertical"
      onValuesChange={onValuesChange}
      initialValues={initialValues}
      className={styles.form}
      id="fishing_report_form"
    >
      {(isError(loadingState) || authError) && errorMessage}
      {waterbody ? (
        <p
          className={classNames({
            [styles.existingFormDetail]: isEdit,
            [styles.existingFormDetail__waterbody]: !isEdit,
          })}
        >
          <strong>Waterbody</strong>
          {!isEdit && ':'} {waterbody?.primary_name}
        </p>
      ) : (
        <>
          <Form.Item
            className={styles.item}
            name={formFields.waterbody}
            id={formFields.waterbody}
            rules={[{ required: true, message: 'Please choose a waterbody' }]}
            label="Waterbody"
          >
            <WaterbodySearchSelect
              placeholder="Ray Roberts Lake"
              suffixIcon={<SearchOutlined />}
              showSearch
              allowClear
              autoFocus
              labelInValue
              waterbodyLookupValueProp="id"
              onChange={(val: WaterbodySelectValue) => onWaterbodyChange(val)}
            />
          </Form.Item>
        </>
      )}

      {isEdit ? (
        <div className={styles.formEdit}>
          {outing_date && (
            <p>
              <strong>Outing Date</strong> {dayjs(outing_date).format('MMMM D, YYYY')}
            </p>
          )}
          <p>
            <strong>Season</strong>{' '}
            <span className={styles.seasonValueIcon}>
              <SeasonIcon
                seasonName={selectedSeason.name}
                className={styles.seasonIcon}
                tag="span"
              />{' '}
              {selectedSeason.display_name}
            </span>
          </p>

          <p>
            <strong>Species</strong>
            <span className={styles.speciesValueIcon}>
              {selectedSpecies.image && <img src={selectedSpecies.image} />}
              {selectedSpecies.display_name}
            </span>
          </p>

          <p>
            <strong>Structure</strong> {selectedStructure.display_name}
          </p>

          {selectedForage && (
            <p>
              <strong>Forage</strong> {selectedForage.display_name}
            </p>
          )}
          <p>
            <strong>Technique</strong> {selectedTechnique.display_name}
          </p>
          {hotbaitFormItem}
          {productsFormItem}
        </div>
      ) : (
        <>
          <div className={styles.outingDateRow}>
            <Form.Item
              name={formFields.outing_date}
              id={formFields.outing_date}
              label="Outing Date"
              className={styles.item}
              rules={[{ required: true, message: 'Please choose an outing date' }]}
            >
              <DatePicker
                format={'MM/DD/YYYY'}
                onChange={onDateChange}
                disabledDate={(current) => {
                  return dayjs().add(1).format('YYYY/MM/DD') < current.format('YYYY/MM/DD');
                }}
                getPopupContainer={() => document.getElementById('fishing_report_form')}
              />
            </Form.Item>
            {selectedOutingDate && waterbodyDetail && (
              <Form.Item
                name={formFields.season}
                id={formFields.season}
                className={classNames(styles.item, styles.selectItem)}
              >
                <span className={styles.seasonItem}>Season</span>
                <span className={styles.waterbodySeason}>
                  <SeasonIcon seasonName={outingDateSeason.name} /> {outingDateSeason.display_name}
                </span>
              </Form.Item>
            )}
          </div>
          <Form.Item
            className={classNames(styles.item, styles.selectItem)}
            name={formFields.species}
            id={formFields.species}
            rules={[{ required: true, message: 'Please choose a species' }]}
            label="Species"
          >
            <Select
              placeholder="Please choose a species"
              getPopupContainer={(trigger) => trigger.parentNode}
              showSearch
              allowClear
              filterOption={(input, option) =>
                String(option.props.children).toLowerCase().includes(input.toLowerCase())
              }
            >
              {formSpecies.map((specie) => (
                <Select.Option value={specie.name} key={specie.name}>
                  {specie.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            className={classNames(styles.item, styles.selectItem)}
            name={formFields.style}
            id={formFields.style}
            rules={[{ required: true, message: 'Please choose a technique' }]}
            label="Technique"
          >
            <Select
              placeholder="Please choose a technique"
              getPopupContainer={(trigger) => trigger.parentNode}
              showSearch
              filterOption={(input, option) =>
                String(option.props.children).toLowerCase().includes(input.toLowerCase())
              }
              allowClear
            >
              {recommendedTechniques.length > 0 ? (
                <>
                  {recommendedTechniques.length > 0 && (
                    <Select.OptGroup label="Recommended Techniques">
                      {recommendedTechniques.map((technique) => (
                        <Select.Option value={technique.name} key={technique.display_name}>
                          {technique.display_name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  )}
                  {otherTechniques.length > 0 && (
                    <Select.OptGroup label={recommendedTechniques.length && 'Other Techniques'}>
                      {otherTechniquesSorted.map((technique) => (
                        <Select.Option value={technique.name} key={technique.display_name}>
                          {technique.display_name}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  )}{' '}
                </>
              ) : (
                <>
                  {otherTechniquesSorted.map((technique) => (
                    <Select.Option value={technique.name} key={technique.display_name}>
                      {technique.display_name}
                    </Select.Option>
                  ))}
                </>
              )}
            </Select>
          </Form.Item>
          <Form.Item
            className={classNames(styles.item, styles.selectItem)}
            name={formFields.structure}
            id={formFields.structure}
            rules={[{ required: true, message: 'Please choose a structure' }]}
            label="Structure"
          >
            <Select
              placeholder="Please choose a structure"
              getPopupContainer={(trigger) => trigger.parentNode}
              showSearch
              filterOption={(input, option) =>
                String(option.props.children).toLowerCase().includes(input.toLowerCase())
              }
              allowClear
            >
              {structures.map((structure) => (
                <Select.Option value={structure.name} key={structure.name}>
                  {structure.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {hotbaitFormItem}
          {productsFormItem}

          <Form.Item
            className={classNames(styles.item, styles.selectItem)}
            name={formFields.forage}
            id={formFields.forage}
            label="Forage"
          >
            <Select
              placeholder="Please choose a forage"
              getPopupContainer={(trigger) => trigger.parentNode}
              showSearch
              filterOption={(input, option) =>
                String(option.props.children).toLowerCase().includes(input.toLowerCase())
              }
              allowClear
            >
              <Select.Option value={undefined} key="none">
                None
              </Select.Option>
              {forages.map((forage) => (
                <Select.Option value={forage.name} key={forage.name}>
                  {forage.display_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name={formFields.water_temperature}
            id={formFields.water_temperature}
            label="Water Temperature (°F)"
            className={styles.item}
          >
            <InputNumber min={32} max={95} />
          </Form.Item>
        </>
      )}
      {initialValues[formFields.comments] == null ? (
        <Form.Item
          className={styles.item}
          name={formFields.comments}
          id={formFields.comments}
          label="Comments"
        >
          <Input.TextArea rows={4} placeholder="Add some additional information here" />
        </Form.Item>
      ) : (
        <p className={styles.existingFormDetail__comments}>
          <strong>Comments</strong> <i>(Cannot edit comments)</i>
          <br />
          <span>{initialValues[formFields.comments]}</span>
        </p>
      )}
      <Form.Item
        name={formFields.img}
        id={formFields.img}
        label="Photo (JPEG, PNG)"
        className={styles.item}
      >
        <Input type="file" accept="image/jpeg, image/jpg, image/png" />
      </Form.Item>
      {videoAllowed && (
        <>
          <Form.Item
            name={formFields.video}
            id={formFields.video}
            label="Video"
            className={styles.item}
          >
            <Upload
              accept="video/*"
              action={`${getApiBaseUrlV1()}videos`}
              headers={{
                Authorization: `Bearer ${accessToken}`,
              }}
              fileList={fileList}
              onChange={onFileChange}
              onRemove={() => setFileList([])}
            >
              <OmniaButton kind="tertiary" size="lg" className={styles.noPadding}>
                <label htmlFor="video" className={styles.labelHack}>
                  <UploadOutlined /> Click to Upload
                </label>
              </OmniaButton>
            </Upload>
          </Form.Item>
          <Form.Item name={formFields.video_id} id={formFields.video_id} hidden>
            <Input type="hidden" />
          </Form.Item>
        </>
      )}
      {isNotLoggedIn && UserForm}
      {(isError(loadingState) || authError) && errorMessage}
    </Form>
  );
};
