import { FishingReport } from '@omniafishing/core';
import { Modal } from 'antd';
import React from 'react';
import { useResponsive } from '../../hooks/use_responsive';
import { NAME_FALLBACK } from '../../lib/user';
import { WebAnalytics } from '../../lib/web_analytics';
import { AmbassadorLink } from '../ambassador_link/ambassador_link';
import { FishingReportProduct } from '../fishing_report_card/fishing_report_product';
import styles from './fishing_reports_carousel.less';

export const ProductsModal = ({
  onModalChange,
  open,
  position,
  report,
}: {
  onModalChange: () => void;
  open: boolean;
  position: number;
  report: FishingReport;
}) => {
  const { isDesktop, isMobile, isOverTablet } = useResponsive();
  const { featured_product, list } = report;
  const listProducts = list?.products || [];
  const fishingReportProducts = [featured_product, ...listProducts].filter(Boolean);

  return (
    <Modal
      open={open}
      onCancel={onModalChange}
      footer={null}
      destroyOnClose
      width={isOverTablet ? '50vw' : '90vw'}
      className={styles.productsModalWrapper}
      centered
    >
      <div className={styles.productsModal}>
        <strong>
          Products From{' '}
          <span>
            <AmbassadorLink
              ambassador={report.user}
              onClick={() => {
                WebAnalytics.fishingReportEmbeddedClick(
                  '[reports].(user_link)',
                  report,
                  'embedded',
                  position
                );
              }}
              target={isDesktop ? '_blank' : undefined}
            >
              {report.user.full_name || NAME_FALLBACK}
            </AmbassadorLink>
          </span>
          's Fishing Report
        </strong>
        <span className={styles.productContainer}>
          {fishingReportProducts.map((p, i) => (
            <div key={p.sku + i}>
              <FishingReportProduct
                product={p}
                position={null}
                isHotbait={p.sku === featured_product?.sku}
                fishingReport={report}
                imgContainerClassName={styles.productCardImageContainer}
                buttonSize={isMobile ? 'sm' : 'md'}
                className={styles.productCard}
              />
            </div>
          ))}
        </span>
      </div>
    </Modal>
  );
};
