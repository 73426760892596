import { LoadingOutlined } from '@ant-design/icons';
import { FishingReport, FishingReportWithWaterbodyDetail } from '@omniafishing/core';
import { Modal } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import iconPlay from '../../assets/icon-play.svg';
import { useResponsive } from '../../hooks/use_responsive';
import { getImgixPath } from '../../lib/imgix';
import { OmniaUrls } from '../../lib/urls';
import { NAME_FALLBACK } from '../../lib/user';
import { WebAnalytics } from '../../lib/web_analytics';
import { LineItemCustomAttribute } from '../../redux/cart';
import { AmbassadorLink } from '../ambassador_link/ambassador_link';
import ImageLoader from '../image_loader/image_loader';
import useImageLoader from '../image_loader/use_image_loader';
import { OmniaButton } from '../omnia_button/omnia_button';
import SvgExpandIcon from '../svg/expand_icon';
import { WaterbodyImg } from '../waterbody_img/waterbody_img';
import { ChipDetails } from './chip_details';
import styles from './fishing_report_card.less';
import { FishingReportCardProductCarousel } from './fishing_report_card_product_carousel';
import { ActiveMedia, MediaModal } from './media_modal';
import { ReportListDetails } from './report_list_details';
import { ShareReportButton } from './share_report_button';

interface FishingReportCardProps {
  alternateHeroPixelWidth?: number;
  carouselClassName?: string;
  className?: string;
  defaultMediaType?: 'image' | 'video';
  extraFooter?: React.ReactNode;
  fishingReport: FishingReport | FishingReportWithWaterbodyDetail;
  heroContainerClassName?: string;
  lineItemAttribution?: LineItemCustomAttribute[];
  mapPixelHeight?: number;
  mapPixelWidth?: number;
  mapClassName?: string;
  newBadge?: boolean;
  onLakeClick?: () => void;
  onMediaClick?: () => void;
  onProductClick?: () => void;
  onShareClick?: () => void;
  onUserClick?: () => void;
  position: number;
  productCardClassName?: string;
  reportedOnBadge?: boolean;
  reportProductInsteadOfCarousel?: React.ReactNode;
  showLakeName?: boolean;
  showUserImage?: boolean;
  showUserName?: boolean;
  showUserStatus?: boolean;
  userImageSize?: number;
}

export const HERO_PIXEL_WIDTH = 430;
const MAP_PIXEL_HEIGHT = 327; // TODO: map image handling is gross. fix me when time.

export const FishingReportCard = (props: FishingReportCardProps) => {
  const {
    alternateHeroPixelWidth,
    carouselClassName,
    className,
    defaultMediaType = 'image',
    extraFooter,
    fishingReport,
    heroContainerClassName,
    lineItemAttribution = [],
    mapClassName,
    mapPixelHeight,
    mapPixelWidth,
    newBadge,
    onLakeClick,
    onMediaClick,
    onProductClick,
    onShareClick,
    onUserClick,
    position,
    productCardClassName,
    reportedOnBadge,
    reportProductInsteadOfCarousel,
    showLakeName,
    showUserImage,
    showUserName,
    showUserStatus,
    userImageSize,
  } = props;

  const { comments, image, featured_product, user, video, waterbody } = fishingReport;

  const { isDesktop, isOverTablet } = useResponsive();

  const userFullName = user.full_name || NAME_FALLBACK;

  const hasImage = !!image;
  const hasVideo = !!video;
  const hasMedia = hasImage || hasVideo;
  const hasImageAndVideo = hasImage && hasVideo;
  const [mediaType, setMediaType] = useState(
    hasImageAndVideo ? defaultMediaType : hasImage ? 'image' : 'video'
  );

  const listProducts = fishingReport?.list?.products || [];
  const fishingReportProducts = [featured_product, ...listProducts].filter(Boolean);
  const hasProducts = fishingReportProducts.length > 0;

  const [activeExpandedMedia, setActiveExpandedMedia] = useState<ActiveMedia>(null);
  const { isImageLoading, isImageError, handleImageLoad, handleImageError, handleImageReload } =
    useImageLoader();

  const handleMediaClick = (
    e: React.MouseEvent<HTMLImageElement | HTMLButtonElement, MouseEvent>,
    type: 'image' | 'video'
  ) => {
    e.stopPropagation();

    if (type === 'image') {
      setActiveExpandedMedia({
        src: getImgixPath(image, { w: 1000 }),
        type: 'img',
      });
    } else {
      setActiveExpandedMedia({
        src: video.src,
        type: 'video',
      });
    }
    onMediaClick?.();
    WebAnalytics.fishingReportEmbeddedClick(
      '[reports].(media_link)',
      fishingReport,
      'embedded',
      position
    );
  };

  const imagePath =
    image &&
    getImgixPath(image, {
      w: alternateHeroPixelWidth || HERO_PIXEL_WIDTH,
      crop: 'faces',
    });

  const [isImageLandscape, setIsImageLandscape] = useState(false);
  const checkAspectRatio = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const { naturalWidth, naturalHeight } = e.currentTarget;
    const aspectRatio = naturalWidth / naturalHeight;
    if (aspectRatio >= 1.5) {
      setIsImageLandscape(true);
    }
  };

  const imageVideoPath =
    video &&
    getImgixPath(video.image, {
      w: alternateHeroPixelWidth || HERO_PIXEL_WIDTH,
      crop: 'faces',
    });

  const Video = video && (
    <>
      <img className={styles.imgBackground} src={imageVideoPath} />
      <ImageLoader
        className={styles.videoImage}
        isImageError={isImageError}
        isImageLoading={isImageLoading}
        loadingClassName={styles.loading}
        onClick={(e) => handleMediaClick(e, 'image')}
        onError={handleImageError}
        onLoad={(e) => {
          handleImageLoad();
          checkAspectRatio(e);
        }}
        src={imageVideoPath}
      />
      {hasImageAndVideo && (
        <span
          className={styles.seeMediaChip}
          onClick={() => {
            setMediaType('image');
            handleImageReload();
          }}
        >
          See Image
        </span>
      )}
      {!isImageError && (
        <>
          <img
            src={iconPlay}
            className={styles.bigPlayIcon}
            onClick={(e) => handleMediaClick(e, 'video')}
          />
          <button className={styles.expandIcon} onClick={(e) => handleMediaClick(e, 'video')}>
            <SvgExpandIcon />
          </button>
        </>
      )}
    </>
  );

  const Image = image && (
    <>
      <img className={styles.imgBackground} src={imagePath} />
      <ImageLoader
        className={classNames(styles.image, { [styles.image__landscape]: isImageLandscape })}
        isImageError={isImageError}
        isImageLoading={isImageLoading}
        loadingClassName={styles.loading}
        onClick={(e) => handleMediaClick(e, 'image')}
        onError={handleImageError}
        onLoad={(e) => {
          handleImageLoad();
          checkAspectRatio(e);
        }}
        src={imagePath}
      />
      {hasImageAndVideo && (
        <span
          className={styles.seeMediaChip}
          onClick={() => {
            setMediaType('video');
            handleImageReload();
          }}
        >
          See Video
        </span>
      )}
      {!isImageError && (
        <button className={styles.expandIcon} onClick={(e) => handleMediaClick(e, 'image')}>
          <SvgExpandIcon />
        </button>
      )}
    </>
  );

  const [mapImageLoaded, setMapImageLoaded] = useState(false);

  const chipDetails = (
    <ChipDetails
      fishingReport={fishingReport}
      onUserClick={onUserClick}
      position={position}
      showLakeName={showLakeName}
      showUserName={showUserName}
      showUserImage={showUserImage}
      showUserStatus={showUserStatus}
      userImageSize={userImageSize}
    />
  );

  const commentsRef = useRef(null);
  const [initialClamp, setInitialClamp] = useState(false);
  const [expandedClamp, setExpandedClamp] = useState(false);
  const [commentsExpanded, setCommentsExpanded] = useState(false);
  const [openCommentsModal, setOpenCommentsModal] = useState(false);

  const detectClamp = (setClamp: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (commentsRef.current) {
      setClamp(commentsRef.current.scrollHeight > commentsRef.current.clientHeight);
    }
  };

  const handleResize = () => detectClamp(setInitialClamp);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    detectClamp(setInitialClamp);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (commentsExpanded) {
      const handleTransitionEnd = () => {
        detectClamp(setExpandedClamp);
        commentsRef.current.removeEventListener('transitionend', handleTransitionEnd);
      };
      commentsRef.current.addEventListener('transitionend', handleTransitionEnd);
    } else {
      detectClamp(setInitialClamp);
      setExpandedClamp(false);
    }
  }, [commentsExpanded]);

  return (
    <div
      className={classNames(styles.fishingReportCard, className, {
        [styles.fishingReportCard__noProducts]: !hasProducts,
      })}
    >
      <div className={styles.hero}>
        {hasMedia ? (
          <div
            className={classNames(styles.mediaContainer, heroContainerClassName, {
              [styles.heroContainer__fullComments]: commentsExpanded && isDesktop,
              [styles.mediaContainer__error]: isImageError,
            })}
          >
            {mediaType === 'image' ? Image : Video}
            {chipDetails}
            <ShareReportButton fishingReport={fishingReport} onShareClick={onShareClick} />
            {newBadge && <span className={styles.newBadge}>New</span>}
            {reportedOnBadge && (
              <span className={styles.badge__reportedDate}>
                Reported on {fishingReport?.waterbody?.primary_name}
              </span>
            )}
            {!isImageError && (
              <MediaModal
                activeMedia={activeExpandedMedia}
                onCancel={() => setActiveExpandedMedia(null)}
              />
            )}
          </div>
        ) : (
          <div
            className={classNames(styles.mapContainer, heroContainerClassName, {
              [styles.heroContainer__fullComments]: commentsExpanded && isDesktop,
            })}
          >
            {!mapImageLoaded && (
              <div className={styles.loading}>
                <LoadingOutlined style={{ fontSize: 24 }} spin />
              </div>
            )}
            <Link
              to={OmniaUrls.waterbody(waterbody)}
              className={styles.waterbodyLink__map}
              onClick={() => {
                onLakeClick?.();
              }}
              target={isDesktop ? '_blank' : undefined}
            >
              <div className={classNames(mapClassName, styles.map)}>
                <WaterbodyImg
                  waterbody={waterbody}
                  height={mapPixelHeight || MAP_PIXEL_HEIGHT}
                  width={mapPixelWidth || HERO_PIXEL_WIDTH}
                  showPin={false}
                  onLoad={() => setMapImageLoaded(true)}
                  size={{
                    width: mapPixelWidth || HERO_PIXEL_WIDTH,
                    height: mapPixelHeight || MAP_PIXEL_HEIGHT,
                  }}
                />
              </div>
            </Link>
            {chipDetails}
            <ShareReportButton fishingReport={fishingReport} onShareClick={onShareClick} />
            {newBadge && <span className={styles.newBadge}>New</span>}
            {reportedOnBadge && (
              <span className={styles.badge__reportedDate}>
                Reported on {fishingReport?.waterbody?.primary_name}
              </span>
            )}
          </div>
        )}
      </div>
      <div className={styles.reportDetails}>
        <ReportListDetails report={fishingReport} />
        {comments && (
          <div className={styles.commentsWrapper}>
            <q
              className={classNames(styles.comments, {
                [styles.comments__expanded]: commentsExpanded,
              })}
              ref={commentsRef}
            >
              {comments}
            </q>{' '}
            <div className={styles.commentsButtonsWrapper}>
              {(initialClamp || commentsExpanded) && (
                <OmniaButton
                  kind="text"
                  size="sm"
                  onClick={() => {
                    setCommentsExpanded(!commentsExpanded);
                  }}
                  style={{ padding: 0 }}
                >
                  {commentsExpanded ? 'Read Less' : 'Read More'}
                </OmniaButton>
              )}
              {commentsExpanded &&
                expandedClamp && ( // expanded, but still clamped
                  <>
                    <OmniaButton
                      kind="text"
                      size="sm"
                      onClick={() => {
                        setOpenCommentsModal(true);
                      }}
                      style={{ padding: 0 }}
                    >
                      See All Comments
                    </OmniaButton>
                  </>
                )}
            </div>
          </div>
        )}
        {hasProducts &&
          (reportProductInsteadOfCarousel ? (
            reportProductInsteadOfCarousel
          ) : (
            <FishingReportCardProductCarousel
              fishingReport={fishingReport}
              lineItemAttribution={lineItemAttribution}
              onProductClick={onProductClick}
              productCardClassName={productCardClassName}
              className={carouselClassName}
            />
          ))}
        {extraFooter}
      </div>
      {expandedClamp && (
        <Modal
          open={openCommentsModal}
          onCancel={() => setOpenCommentsModal(false)}
          footer={null}
          destroyOnClose
          width={isOverTablet ? '50vw' : '90vw'}
          className={styles.commentsModalWrapper}
          centered
        >
          <div className={styles.commentsModal}>
            <strong>
              Comments From{' '}
              <span>
                <AmbassadorLink
                  ambassador={user}
                  onClick={() => {
                    onUserClick?.();
                    WebAnalytics.fishingReportEmbeddedClick(
                      '[reports].(user_link)',
                      fishingReport,
                      'embedded',
                      position
                    );
                  }}
                  target={isDesktop ? '_blank' : undefined}
                >
                  {userFullName}:
                </AmbassadorLink>
              </span>
            </strong>

            <span>{comments}</span>
          </div>
        </Modal>
      )}
    </div>
  );
};
