import { DollarOutlined, InfoCircleOutlined, TagOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { OmniaLinkButton } from '../../components/omnia_button/omnia_link_button';
import { ProTrialButton } from '../../components/pro_buttons/pro_trial_button';
import { Popover, PopoverContent, PopoverTrigger } from '../../components/shadcn/popover';
import SvgLayerStorm from '../../components/svg/layer_storm';
import SvgLure from '../../components/svg/lure';
import SvgMap from '../../components/svg/map';
import SvgOmniaProLogo from '../../components/svg/omnia_pro_logo';
import SvgShipped from '../../components/svg/shipped';
import { PRO_TRIAL_PURCHASE_URL } from '../../lib/stripe';
import { WebAnalytics } from '../../lib/web_analytics';
import { RoutePaths } from '../../routes';
import styles from './pro_banner.less';

// TODO: this would be simpler as a tooltip with onClick for mobile than a popover with onMouseEnter etc.
const BannerPopover = ({ icon }: { icon: React.ReactNode }) => {
  const Icon = icon;
  const [open, setOpen] = useState(false);

  const debouncedClose = useCallback(
    debounce(() => setOpen(false), 150),
    []
  );

  const handleMouseEnter = () => {
    if (debouncedClose) {
      debouncedClose.cancel();
    }
    setOpen(true);
  };

  const handleMouseLeave = () => {
    debouncedClose();
  };
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {Icon}
      </PopoverTrigger>
      <PopoverContent
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="bg-white p-3 rounded-lg"
      >
        <div className="max-w-[320px] sm:max-w-[410px]">
          <span className="text-[20px] font-medium ">Omnia PRO includes:</span>
          <p className="mt-4 mb-3">
            <strong>Omnia PRO</strong> members enjoy both transactional and intelligence-based
            benefits designed to enhance your fishing experience:
          </p>
          <ul className={classNames(styles.list, 'mb-5')}>
            <li>
              <DollarOutlined />
              <span>
                <strong>10% Back in Credit</strong> on every purchase, automatically added to your
                account.
              </span>
            </li>
            <li>
              <SvgShipped height="24px" width="24px" />
              <span>
                <strong>Fast, Free Shipping</strong> on over 20,000 items with no minimums.
              </span>
            </li>
            <li>
              <SvgMap width="23px" height="23px" className="!min-w-[22px] mr-[2px] mt-[3px]" />
              <span>
                <strong>Detailed Fishing Maps:</strong> Access Navionics and C-MAP Charts, including
                depth contours, water clarity, temperature insights, and more.
              </span>
            </li>
            <li>
              <SvgLure width="27px" height="27px" className="!min-w-[29px] -mx-[3px]" />
              <span>
                <strong>Expert Gear Recommendations:</strong> Tailored suggestions based on lake
                conditions and fishing reports.
              </span>
            </li>
            <li>
              <SvgLayerStorm width="24px" className="-mt-[5px]" />
              <span>
                <strong>Advanced Weather Tools:</strong> Real-time wind, cloud cover, and barometric
                pressure data to plan your trips with precision.
              </span>
            </li>
            <li>
              <TagOutlined height="24px" width="24px" />
              <span>
                <strong>Early Access:</strong> to sales, exclusive members-only promotions, and
                private giveaways.
              </span>
            </li>
          </ul>
          <div className="flex justify-start gap-2.5">
            <OmniaLinkButton
              to={RoutePaths.PREMIUM_PRO}
              kind="tertiary"
              size="md"
              onClick={() => {
                WebAnalytics.proButtonClicked({
                  location: 'pdp_banner_top_popover',
                  type: 'learn_more',
                  style: 'button',
                });
              }}
            >
              Learn About Omnia PRO
            </OmniaLinkButton>
            <ProTrialButton
              className={styles.proButton}
              size="md"
              kind="primary"
              text={`Try 7-Days FREE`}
              trialPaymentLinkUrl={PRO_TRIAL_PURCHASE_URL}
              onClick={() => {
                WebAnalytics.proButtonClicked({
                  location: 'pdp_banner_top_popover',
                  type: 'trial',
                  style: 'button',
                });
              }}
            />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export const ProBanner = () => {
  return (
    <>
      <section className={classNames(styles.container, 'text-white text-center p-3')}>
        <div className="flex justify-between">
          <BannerPopover
            icon={
              <span>
                <SvgOmniaProLogo className={styles.logo} />
              </span>
            }
          />
          <BannerPopover icon={<InfoCircleOutlined />} />
        </div>
        <p className="text-left my-3">
          Elevate Your Fishing with Omnia PRO. Unlock Free Shipping, Exclusive Rewards, and
          Pro-Level Fishing Insights. <strong>Try PRO Free for 7 Days </strong> and discover the
          difference with
          <strong> detailed map layers</strong> and 10% back on every purchase.
        </p>
        <div className="flex lg:justify-end flex-wrap gap-3">
          <OmniaLinkButton
            to={RoutePaths.PREMIUM_PRO}
            kind="tertiary"
            size="md"
            onClick={() => {
              WebAnalytics.proButtonClicked({
                location: 'pdp_banner_top',
                type: 'learn_more',
                style: 'button',
              });
            }}
          >
            Learn About Omnia PRO
          </OmniaLinkButton>
          <ProTrialButton
            className={styles.proButton}
            size="md"
            kind="primary"
            text="Try PRO 7-Days FREE"
            trialPaymentLinkUrl={PRO_TRIAL_PURCHASE_URL}
            onClick={() => {
              WebAnalytics.proButtonClicked({
                location: 'pdp_banner_top',
                type: 'trial',
                style: 'button',
              });
            }}
          />
        </div>
      </section>
    </>
  );
};
