import { AlgoliaMedia, Media } from '@omniafishing/core';
import React from 'react';
import { Link } from 'react-router-dom';
import buSrc from '../../assets/bu.svg';
import { getImgixPath } from '../../lib/imgix';
import { OmniaUrls } from '../../lib/urls';
import fallbackImg from './fallback-image.png';
import styles from './search_media.less';

interface SearchMediaProps {
  media: AlgoliaMedia | Media;
  onClick: (target_url?: string) => void;
}

function isAlgoliaMedia(value: AlgoliaMedia | Media): value is AlgoliaMedia {
  return value.hasOwnProperty('_highlightResult');
}

export const SearchMedia = (props: SearchMediaProps) => {
  const { media, onClick } = props;
  const { media_type } = media;

  const title = isAlgoliaMedia(media) ? media.title : media.media_item.title;
  const thumbnail = isAlgoliaMedia(media) ? media.thumbnail : media.media_item.thumbnail;

  const isBassU = media_type === 'BassUArticle';
  const url = isAlgoliaMedia(media) ? OmniaUrls.algoliaMedia(media) : OmniaUrls.media(media);

  const content = (
    <>
      {thumbnail ? (
        <img src={getImgixPath(thumbnail, { w: 300 })} alt={''} loading="lazy" />
      ) : (
        <img src={fallbackImg} alt={''} loading="lazy" />
      )}
      <p className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
    </>
  );

  if (isBassU) {
    return (
      <a href={url} className={styles.link} onClick={() => onClick(url)}>
        {content}
        <span className={styles.source} style={{ backgroundImage: `url(${buSrc})` }}></span>
      </a>
    );
  }

  return (
    <Link to={url} className={styles.link} onClick={() => onClick(url)}>
      {content}
    </Link>
  );
};
