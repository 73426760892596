import _ from 'lodash';
import { FishingReport } from '@omniafishing/core';
import { FishingReportProduct } from './fishing_report_product';
import { LineItemCustomAttribute } from '../../redux/cart';
import { ProductCarousel } from '../carousel/product_carousel';
import { useListAttributionUuid } from '../../hooks/use_list_attribution_uuid';
import classNames from 'classnames';
import React from 'react';
import styles from './fishing_report_card.less';

interface FishingReportCardProductCarouselProps {
  fishingReport: FishingReport;
  lineItemAttribution?: LineItemCustomAttribute[];
  onProductClick?: () => void;
  productCardClassName?: string;
  className?: string;
}

export const FishingReportCardProductCarousel = (props: FishingReportCardProductCarouselProps) => {
  const { fishingReport, lineItemAttribution, onProductClick, productCardClassName, className } =
    props;
  const { featured_product } = fishingReport;
  const list_uuid = useListAttributionUuid();

  const listProducts = fishingReport?.list?.products || [];
  const fishingReportProducts = [featured_product, ...listProducts].filter(Boolean);

  const hasFeaturedProduct = fishingReport.featured_product != null;

  const productItems = React.useMemo(
    () =>
      fishingReportProducts.map((product, i) => {
        const isHotbait = hasFeaturedProduct && i === 0;
        return (
          <li
            key={`${product.sku}_${isHotbait}`}
            className={classNames(styles.productCard, productCardClassName)}
          >
            <FishingReportProduct
              product={product}
              position={i}
              list_uuid={list_uuid}
              onProductClick={onProductClick}
              isHotbait={isHotbait}
              lineItemAttribution={lineItemAttribution}
              fishingReport={fishingReport}
            />
          </li>
        );
      }),
    [fishingReportProducts.map((product) => product.sku).join('')]
  );

  return (
    <ul className={classNames(styles.carouselWrapper, className)}>
      <ProductCarousel
        products={fishingReportProducts}
        key={fishingReportProducts.map((product) => product.sku).join('')}
        list_id_title="Products Associated With This Report"
        list_id_type="report"
        arrows={fishingReportProducts.length > 1}
        list_uuid={list_uuid}
        fadedEdges
        resizeOnContentsReady
      >
        {productItems}
      </ProductCarousel>
    </ul>
  );
};
